<!--
 * @Description: 维护明细统计报表
 * @Author: LiangYiNing
 * @Date: 2022-04-11 13:46:15
 * @LastEditTime: 2022-10-27 11:40:35
 * @LastEditors: ChenXueLin
-->

<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:operateAction>
          <i
            class="e6-icon-upload_fill"
            title="按车导出"
            @click="handleExport(1)"
          ></i>
          <i
            class="e6-icon-export_line"
            title="导出"
            @click="handleExport(0)"
          ></i>
        </template>
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="corpId">
              <e6-vr-select
                v-model="searchForm.corpId"
                :data="corpList"
                placeholder="客户名称"
                title="客户名称"
                clearable
                virtual
                remote
                :is-title="true"
                @filterChange="handleLoadCorpFilter"
                :props="{
                  id: 'corpId',
                  label: 'corpNameStr',
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="sceneName">
              <el-input
                v-model="searchForm.sceneName"
                placeholder="车牌号"
                title="车牌号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpAttrIdList">
              <e6-vr-select
                v-model="searchForm.corpAttrIdList"
                :data="corpAttrEnum"
                placeholder="客户属性"
                title="客户属性"
                :props="{
                  id: 'id',
                  label: 'attrValueDesc',
                }"
                multiple
                clearable
              ></e6-vr-select>
            </el-form-item>

            <el-form-item class="search-item--1" prop="equipType">
              <e6-vr-select
                v-model="searchForm.equipType"
                :data="typeIdEnum"
                placeholder="设备类型"
                title="设备类型"
                :props="{
                  id: 'codeValue',
                  label: 'codeName',
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="itemStatus">
              <e6-vr-select
                v-model="searchForm.itemStatus"
                :data="itemStatusEnum"
                placeholder="任务项状态"
                title="任务项状态"
                :props="{
                  id: 'codeValue',
                  label: 'codeName',
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="createTime"
                type="datetime"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                start-placeholder="创建时间(始)"
                end-placeholder="创建时间(止)"
                :default-time="['00:00:00', '23:59:59']"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="lastRepairTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="上一次维修时间"
                ref="lastRepairTime"
                type="datetime"
                v-model="searchForm.lastRepairTime"
                title="上一次维修时间"
                :picker-options="pickerOptions('searchForm.lastRepairTime')"
                start-placeholder="上一次维修时间(始)"
                end-placeholder="上一次维修时间(止)"
                :default-time="['00:00:00', '23:59:59']"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="fixDateTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="维护时间"
                ref="fixDateTime"
                type="datetime"
                v-model="searchForm.fixDateTime"
                title="上一次维修时间"
                :picker-options="pickerOptions('searchForm.fixDateTime')"
                start-placeholder="维护时间(始)"
                end-placeholder="维护时间(止)"
                :default-time="['00:00:00', '23:59:59']"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="installTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="安装时间"
                ref="installTime"
                type="datetime"
                v-model="searchForm.installTime"
                title="安装时间"
                :picker-options="pickerOptions('searchForm.installTime')"
                start-placeholder="安装时间(始)"
                end-placeholder="安装时间(止)"
                :default-time="['00:00:00', '23:59:59']"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="finishTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="完成时间"
                ref="finishTime"
                type="datetime"
                v-model="searchForm.finishTime"
                title="完成时间"
                :picker-options="pickerOptions('searchForm.finishTime')"
                start-placeholder="完成时间(始)"
                end-placeholder="完成时间(止)"
                :default-time="['00:00:00', '23:59:59']"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="tableList"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import {
  findDownList,
  maintainDetailsReportList,
  maintainDetailsReportExport,
  getCorpAttrDownList,
  getWorkCorpList,
} from "@/api";
import { printError, getMothRangeTimestamp } from "@/utils/util";
import { exportData } from "@/utils/download";

// 按钮ID常量
import setting from "@/setting.js";
let { EXPORT_BUTTON_ID } = setting;

export default {
  name: "MaintainDetailedStatisticsReport",
  data() {
    return {
      TrensferFunsApplyVisible: false,
      total: 0,
      timePickerResetList: [
        "createTime",
        "lastRepairTime",
        "installTime",
        "fixDateTime",
        "finishTime",
      ], // base mixin的配置项 需要重置的timePicker refname
      corpAttrEnum: [], // 客户属性枚举
      typeIdEnum: [], // 设备类型枚举
      itemStatusEnum: [],
      EXPORT_BUTTON_ID,
      queryListAPI: maintainDetailsReportList,
      searchForm: {
        undefinedinput: "",
        undefinedSelect: "",
        corpId: "", // 客户名称
        sceneName: "", //场景名称
        corpAttrIdList: [], // 客户属性id
        equipType: "1", // 设备类型 默认主机
        itemStatus: "", // 任务项状态
        createTime: getMothRangeTimestamp(), // 创建时间
        createdTimeStart: "", // 开始时间
        createdTimeEnd: "", // 结束时间
        lastRepairTime: [], // 上一次维修时间
        lastRepairTimeStart: "", // 上一次维修时间开始
        lastRepairTimeEnd: "", // 上一次维修时间结束
        fixDateTime: [], // 维护时间
        fixDateStart: "", // 维护时间开始
        fixDateEnd: "", // 维护时间结束
        installTime: [], // 安装时间
        installTimeStart: "", // 安装时间开始
        installTimeEnd: "", // 安装时间结束
        finishTime: [], // 完成时间
        finishTimeStart: "", // 完成开始时间
        finishTimeEnd: "", // 完成结束时间
        pageIndex: 1,
        pageSize: 20,
        sortIndex: "createdTime", // 默认创建时间排序
        sortDir: "descending", // 默认降序
      },
      columnData: [
        {
          fieldName: "itemId",
          fieldLabel: "工单ID",
          width: 100,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "fixId",
          fieldLabel: "维护ID",
          width: 100,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "itemStatus",
          fieldLabel: "任务项状态",
          width: 100,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "scheduleid",
          fieldLabel: "调度ID",
          width: 100,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "isSign",
          fieldLabel: "是否上门",
          width: 100,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "secondClass",
          fieldLabel: "任务类型",
          width: 100,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "fixType",
          fieldLabel: "操作类型",
          width: 100,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "equOprTypeName",
          fieldLabel: "设备操作类型",
          width: 100,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "checkResult",
          fieldLabel: "是否审核",
          width: 90,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "corpName",
          fieldLabel: "客户名称",
          width: 180,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "nowOperationName",
          fieldLabel: "现运营",
          width: 130,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "nowOperationOrgName",
          fieldLabel: "现运营组织",
          width: 130,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "nowSaleName",
          fieldLabel: "现销售",
          width: 130,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "nowSaleOrgName",
          fieldLabel: "现销售组织",
          width: 160,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "signCustomerId",
          fieldLabel: "签约客户ID",
          width: 130,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "companyNoName",
          fieldLabel: "签单组织",
          width: 160,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "attrName",
          fieldLabel: "客户属性",
          width: 160,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "corpLevel",
          fieldLabel: "客户级别",
          width: 120,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "sourceOrganizationName",
          fieldLabel: "上线平台",
          width: 100,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "companyFullName",
          fieldLabel: "所属主体",
          width: 160,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "sceneName",
          fieldLabel: "车牌号",
          width: 120,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "regName",
          fieldLabel: "系统车牌",
          width: 120,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "createdTime",
          fieldLabel: "创建任务项时间",
          width: 160,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "finishTime",
          fieldLabel: "完成时间",
          width: 160,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "employeeName",
          fieldLabel: "维护人",
          width: 120,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "fixDate",
          fieldLabel: "维护时间",
          width: 160,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "lastRepairTime",
          fieldLabel: "上一次维修时间",
          width: 160,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "installTime",
          fieldLabel: "安装时间",
          width: 160,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "firstInstallTime",
          fieldLabel: "首次服务开启时间",
          width: 160,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "firstClassName",
          fieldLabel: "设备一级分类",
          width: 140,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "secondClassName",
          fieldLabel: "设备二级分类",
          width: 160,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "equipClassName",
          fieldLabel: "设备三级分类",
          width: 160,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "equipTypeName",
          fieldLabel: "设备类型",
          width: 100,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "cardOpName",
          fieldLabel: "卡操作",
          width: 120,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "simClassName",
          fieldLabel: "SIM卡三级分类",
          width: 160,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "simBindingTime",
          fieldLabel: "SIM卡绑定时间",
          width: 160,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "simBindingDay",
          fieldLabel: "原SIM卡绑定时长",
          width: 160,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "isChangeSimName",
          fieldLabel: "是否更换SIM卡",
          width: 140,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "address",
          fieldLabel: "维护地点",
          width: 160,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "recordName",
          fieldLabel: "记录人",
          width: 120,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "workNo",
          fieldLabel: "安装单编号",
          width: 160,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "contactName",
          fieldLabel: "第二联系人",
          width: 120,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "contactPhone",
          fieldLabel: "第二联系电话",
          width: 120,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "quesType",
          fieldLabel: "问题类型",
          width: 120,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "quesName",
          fieldLabel: "问题描述",
          width: 160,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "mainBody",
          fieldLabel: "主体",
          width: 160,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "quesReason",
          display: true,
          fieldLabel: "故障现象",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "faultClass",
          display: true,
          fieldLabel: "故障归类",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "solution",
          display: true,
          fieldLabel: "处理措施",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "faultReason",
          display: true,
          fieldLabel: "故障原因",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "lastQuesReason",
          fieldLabel: "上一次故障现象",
          width: 160,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "responsibleEntity",
          display: true,
          fieldLabel: "责任主体",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "equipCode",
          display: true,
          fieldLabel: "新设备号",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "oldEquipCode",
          display: true,
          fieldLabel: "原设备号",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "oldEquipIs2G",
          display: true,
          fieldLabel: "原设备是否是2G设备",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "lastRepairSolution",
          fieldLabel: "上一次维修处理措施",
          width: 160,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "lastRepairEngineer",
          fieldLabel: "上一次维修工单师",
          width: 160,
          fixed: false,
          align: "center",
        },
      ], //表头
      tableData: [],
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.createdTimeStart = val[0] || "";
        this.searchForm.createdTimeEnd = val[1] || "";
      },
    },
    "searchForm.lastRepairTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.lastRepairTime = this.searchForm.lastRepairTime.reverse();
        }
        this.searchForm.lastRepairTimeStart = val[0] || "";
        this.searchForm.lastRepairTimeEnd = val[1] || "";
      },
    },
    "searchForm.installTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.installTime = this.searchForm.installTime.reverse();
        }
        this.searchForm.installTimeStart = val[0] || "";
        this.searchForm.installTimeEnd = val[1] || "";
      },
    },
    "searchForm.fixDateTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.fixDateTime = this.searchForm.fixDateTime.reverse();
        }
        this.searchForm.fixDateStart = val[0] || "";
        this.searchForm.fixDateEnd = val[1] || "";
      },
    },
    // 完成时间
    "searchForm.finishTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.finishTime = this.searchForm.finishTime.reverse();
        }
        this.searchForm.finishTimeStart = val[0] || "";
        this.searchForm.finishTimeEnd = val[1] || "";
      },
    },
  },
  created() {
    this.queryList();
    this.initData();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.searchForm.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    //加载客户名称
    async loadCorpData(val) {
      try {
        let res = await getWorkCorpList({
          pageNum: 1,
          pageSize: 200,
          corpName: val,
        });
        let corpList = res.data.array;
        corpList.map((item) => {
          item.corpNameStr = item.corpName + "(" + item.corpId + ")";
        });
        //获取客户名称
        this.corpList = _.cloneDeep(corpList);
      } catch (error) {
        printError(error);
      }
    },
    // 获取枚举信息
    async initData() {
      try {
        let enumKey = ["itemStatus", "typeId"];
        //获取枚举数据
        let promiseList = [findDownList(enumKey), getCorpAttrDownList({})];
        let [findDownListRes, corpAttrRes] = await Promise.all(promiseList);
        enumKey.map((item) => {
          this[`${item}Enum`] = this.getFreezeResponse(findDownListRes, {
            path: `data.${item}`,
          });
        });
        this.corpAttrEnum = this.getFreezeResponse(corpAttrRes, {
          path: "data",
        });
      } catch (error) {
        printError(error);
      }
    },
    //获取列表数据 这里只写了分页的 非分页的可自行在组件内覆盖
    async queryList() {
      if (!this.queryListAPI) {
        return;
      }
      let { createdTimeStart, createdTimeEnd } = this.searchForm;
      if (!createdTimeStart && !createdTimeEnd) {
        return this.$message.warning("请选择创建起始时间查询！");
      }
      // 自动补充结束时间为1年后
      if (createdTimeStart && !createdTimeEnd) {
        createdTimeEnd = createdTimeStart + 86400000 * 366;
        this.searchForm.createdTimeEnd = createdTimeEnd;
        this.searchForm.createTime = [createdTimeStart, createdTimeEnd];
      }
      // 自动补充结束时间为1年后
      if (!createdTimeStart && createdTimeEnd) {
        createdTimeStart = createdTimeEnd - 86400000 * 366;
        this.searchForm.createdTimeStart = createdTimeStart;
        this.searchForm.createTime = [createdTimeStart, createdTimeEnd];
      }
      // 查询范围不能超过一年
      if (createdTimeEnd - createdTimeStart > 86400000 * 366) {
        return this.$message.warning("查询创建时间范围不能大于1年！");
      }
      try {
        this.loading = true;
        let res = await this.queryListAPI(this.searchForm);
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array",
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords",
        });
        this.elementTableDoLayout();
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 表格勾选
    handleSelectionChange(columns) {
      this.selColumnId = columns.map((item) => {
        return item.simPropId;
      });
    },
    // 导出
    handleExport(isSceneExport) {
      let { createdTimeStart, createdTimeEnd } = this.searchForm;
      if (!createdTimeStart || !createdTimeEnd) {
        return this.$message.warning("请选择创建起始和结束时间查询！");
      }
      if (createdTimeEnd - createdTimeStart > 86400000 * 366) {
        return this.$message.warning("导出创建时间范围不能大于1年！");
      }
      this.searchForm.isSceneExport = isSceneExport;
      exportData(this, maintainDetailsReportExport);
    },
  },
};
</script>
<style lang="scss" scoped></style>
