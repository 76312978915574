<!--
 * @Description:工程调拨申请
 * @Author: ChenXueLin
 * @Date: 2021-10-18 14:41:03
 * @LastEditTime: 2023-03-24 09:53:54
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title>
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item
              class="search-item--1 search-corpName"
              prop="outCompanyNo"
            >
              <e6-vr-select
                v-model="searchForm.outCompanyNo"
                :data="companyList"
                placeholder="调出方主体"
                title="调出方主体"
                clearable
                :props="{
                  id: 'code',
                  label: 'name',
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="outSerialNumber">
              <e6-vr-select
                v-model="searchForm.outSerialNumber"
                :data="outWarehouseList"
                placeholder="调出仓库"
                title="调出仓库"
                ref="outSerialNumber"
                clearable
                :props="{
                  id: 'serialNumber',
                  label: 'warehouseName',
                }"
                @change="
                  (val, node) => {
                    changeWarehouse(val, node, 'outWarehouseNo');
                  }
                "
                @clear="
                  () => {
                    searchForm.outWarehouseNo = '';
                  }
                "
              ></e6-vr-select>
            </el-form-item>
            <el-form-item
              class="search-item--1 search-corpName"
              prop="inCompanyNo"
            >
              <e6-vr-select
                v-model="searchForm.inCompanyNo"
                :data="companyList"
                placeholder="调入方主体"
                title="调入方主体"
                clearable
                :props="{
                  id: 'code',
                  label: 'name',
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="inSerialNumber">
              <e6-vr-select
                v-model="searchForm.inSerialNumber"
                :data="inWarehouseList"
                placeholder="调入仓库"
                title="调入仓库"
                ref="inSerialNumber"
                clearable
                :props="{
                  id: 'serialNumber',
                  label: 'warehouseName',
                }"
                @change="
                  (val, node) => {
                    changeWarehouse(val, node, 'inWarehouseNo');
                  }
                "
                @clear="
                  () => {
                    searchForm.inWarehouseNo = '';
                  }
                "
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="applyType">
              <e6-vr-select
                v-model="searchForm.applyType"
                :data="allocationApplyTypeList"
                placeholder="调拨类型"
                title="调拨类型"
                clearable
                :props="{
                  id: 'codeId',
                  label: 'codeName',
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="applyNo">
              <el-input
                v-model="searchForm.applyNo"
                placeholder="申请单号"
                title="申请单号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="inEngineerName">
              <el-input
                v-model="searchForm.inEngineerName"
                placeholder="调入工程师"
                title="调入工程师"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="equipNos">
              <input-plus
                v-model="searchForm.equipNos"
                valueType="Array"
                placeholder="设备编号"
                title="设备编号"
                label="设备编号"
                clearable
              ></input-plus>
            </el-form-item>
            <el-form-item class="search-item--1" prop="applyStatus">
              <e6-vr-select
                v-model="searchForm.applyStatus"
                :data="allocationApplyStatusList"
                placeholder="调拨状态"
                title="调拨状态"
                clearable
                :props="{
                  id: 'codeId',
                  label: 'codeName',
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="examineStatus">
              <e6-vr-select
                v-model="searchForm.examineStatus"
                :data="allocationExamineStatusList"
                placeholder="审核状态"
                title="审核状态"
                :props="{
                  id: 'codeId',
                  label: 'codeName',
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="applyUserId">
              <remote-search
                v-model="searchForm.applyUserId"
                :remote="true"
                clearable
                placeholder="申请人"
                title="申请人"
                searchValueKey="userName"
                :props="{
                  id: 'userId',
                  label: 'userName',
                }"
                :queryListAPI="getUserList"
              ></remote-search>
            </el-form-item>
            <el-form-item class="search-item--2" prop="orderReviewTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="审核时间"
                ref="orderReviewTime"
                v-model="searchForm.orderReviewTime"
                title="审核时间"
                :picker-options="pickerOptions('searchForm.orderReviewTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="审核时间（始）"
                end-placeholder="审核时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-add_line" title="新增" @click="handleAdd"></i>
          <i class="e6-icon-clear_line" title="删除" @click="handleDelete"></i>
          <i title="审核" @click="handleAudit">审核</i>
          <i title="销审" @click="handleMarketingAudit">销审</i>
          <i title="发货出库" @click="handlePick">发货出库</i>
          <i
            class="e6-icon-export_line"
            title="导出"
            @click="handleExportDialog"
          ></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          ref="tableList"
          @selection-change="handleSelectionChange"
          @row-click="handleRowClick"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList(scope.row)"
                @command="handleOperate(scope.row, $event)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section
        class="pagination-wrapper fixed-section"
        ref="paginationWrapper"
        style="position:relative"
      >
        <div class="e6-title" @click="foldDetail">
          调拨详情/
          <el-button type="text">{{
            !isFoldDetailTable ? "展开" : "收起"
          }}</el-button>
        </div>
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
      <!-- 明细 -->
      <section class="fixed-section" v-if="isFoldDetailTable">
        <el-table
          border
          :height="200 + 'px'"
          :data="detailTableData"
          highlight-current-row
        >
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{ scope.$index + 1 }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in detailColumnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
        </el-table>
      </section>
    </div>
    <!-- 新增编辑弹框 -->
    <add-transfer-dialog
      :addVisible.sync="addVisible"
      :dialogType="dialogType"
      @refreshData="queryList"
      :addType="addType"
      :id="editId"
    ></add-transfer-dialog>
    <!-- 调拨详情 -->
    <transfer-detail-dialog
      :id="editId"
      :dialogType="dialogType"
      :detailVisible.sync="detailVisible"
    ></transfer-detail-dialog>
    <!-- 发货出库弹框 -->
    <pick-product-dialog
      :pickVisible.sync="pickVisible"
      dialogTitle="发货出库"
      dialogType="1"
      :applyIdList="applyIdList"
      @refreshData="queryList"
    ></pick-product-dialog>
    <!-- 确认入库 -->
    <confirmation-warehouse
      :confirmVisible.sync="confirmVisible"
      :clickRow="clickRow"
      @refreshData="queryList"
    ></confirmation-warehouse>
    <!-- 扫描输入框 -->
    <scan-input :inputVisible.sync="inputVisible"></scan-input>
    <!-- 销审 -->
    <rejected-dialog
      :rejectedVisible.sync="rejectedVisible"
      @confirm="confirm"
      @refreshData="queryList"
      title="销审"
      tipMessage="销审，请确认!"
      ref="rejectedDialog"
    ></rejected-dialog>
    <!-- 操作日志 -->
    <log-dialog
      :logVisible.sync="logVisible"
      :applyId="clickRow.applyId"
    ></log-dialog>
    <!-- 导出弹框 -->
    <export-dialog
      :exportVisible.sync="exportVisible"
      @confirm="handleExport"
      @refreshData="queryList"
      ref="exportDialog"
      tableId="18"
    ></export-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import {
  queryEngineeringAllocationApply,
  getAllocationListByApplyId,
  getUserList,
  exportEngineerDispatcherList,
  queryWarehouse,
} from "@/api";
import AddTransferDialog from "./components/addTransferDialog.vue";
import PickProductDialog from "./components/pickProductDialog.vue";
import ConfirmationWarehouse from "./components/confirmationWarehouse.vue";
import ScanInput from "./components/scanInput/scanInput.vue";
import LogDialog from "./components/logDialog/logDialog.vue";
import transfersManage from "./mixins/index";
import RejectedDialog from "@/components/rejectedDialog.vue";
import inputPlus from "@/components/inputPlus";
import transferDetailDialog from "./components/transferDetailDialog.vue";
import exportDialog from "@/components/exportDialog/index";
import { exportData } from "@/utils/download";
export default {
  name: "workTransfersApply",
  data() {
    return {
      getUserList,
      queryWarehouse,
      dialogType: 2,
      isFoldDetailTable: false,
      searchForm: {
        equipNos: [],
        inEngineerName: "",
        applyUserId: "",
        outCompanyNo: "", //公司主体
        applyType: "", //调拨类型
        outWarehouseNo: "", //调出仓库
        outSerialNumber: "",
        inWarehouseNo: "", //调入仓库
        inSerialNumber: "",
        applyNo: "", //申请单号
        inCompanyNo: "",
        applyStatus: "", //调拨状态
        examineStatus: "", //审核状态
        pageIndex: 1,
        pageSize: 20,
        orderReviewTime: [],
        startExamineTime: "",
        endExamineTime: "",
      },
      timePickerResetList: ["orderReviewTime"],
      columnData: [
        {
          fieldName: "applyNo",
          display: true,
          fieldLabel: "申请单号",
          width: 180,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "outCompanyName",
          display: true,
          fieldLabel: "公司主体",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "applyTypeName",
          display: true,
          fieldLabel: "调拨类型",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "outWarehouseName",
          display: true,
          fieldLabel: "出库仓",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "inWarehouseName",
          display: true,
          fieldLabel: "入库仓",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "inEngineerName",
          display: true,
          fieldLabel: "调入工程师名称",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "outEngineerName",
          display: true,
          fieldLabel: "调出工程师名称",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "applyUserName",
          display: true,
          fieldLabel: "申请人",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "auditUserName",
          display: true,
          fieldLabel: "审核人",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "examineStatusName",
          display: true,
          fieldLabel: "审核状态",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "applyStatusName",
          display: true,
          fieldLabel: "调拨状态",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "reasonName",
          display: true,
          fieldLabel: "调拨原因",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "contactName",
          display: true,
          fieldLabel: "收货联系人",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "contactPhone",
          display: true,
          fieldLabel: "联系电话",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "detailAddress",
          display: true,
          fieldLabel: "收货地址",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "remark",
          display: true,
          fieldLabel: "备注",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center",
        },
      ], //表头
      applyIdList: [],
      queryListAPI: queryEngineeringAllocationApply,
      detailApi: getAllocationListByApplyId,
      logVisible: false,
      clickRow: {},
      detailVisible: false,
      exportVisible: false,
    };
  },
  watch: {
    // 创建开始时间
    "searchForm.orderReviewTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.orderReviewTime = this.searchForm.orderReviewTime.reverse();
        }
        this.searchForm.startExamineTime = val[0] || "";
        this.searchForm.endExamineTime = val[1] || "";
      },
    },
  },
  components: {
    AddTransferDialog,
    PickProductDialog,
    ConfirmationWarehouse,
    ScanInput,
    RejectedDialog,
    inputPlus,
    LogDialog,
    transferDetailDialog,
    exportDialog,
  },
  mixins: [base, listPage, listPageReszie, transfersManage],
  computed: {},
  created() {
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("tableList");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    //点击导出
    handleExportDialog() {
      this.exportVisible = true;
    },
    // 导出
    handleExport(excludeFields) {
      exportData(this, exportEngineerDispatcherList, {
        ...this.searchForm,
        excludeFields,
      });
    },
    // 获取页面操作权限
    getOperateList(row) {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1,
          disabled: row.examineStatus == "4026002",
        },
        {
          label: "详情",
          id: 2,
        },
        {
          label: "确认入库",
          id: 3,
          disabled: !row.inWarehouseApplyId || row.applyStatus != 4025002,
        },
        {
          label: "操作日志",
          id: 4,
        },
      ];
      return operates;
    },
    // 操作--更多
    handleOperate(row, val) {
      //编辑
      if (val.id == 1) {
        this.addType = 2;
        this.handleEdit(row);
      }
      //详情
      if (val.id == 2) {
        this.editId = row.applyId;
        this.detailVisible = true;
      }
      //确认入库
      if (val.id == 3) {
        this.handleReceive(row);
      }
      if (val.id == 4) {
        this.logVisible = true;
        this.clickRow = row;
      }
    },
    //点击详情table折叠收起
    foldDetail() {
      this.isFoldDetailTable = !this.isFoldDetailTable;
      this.$nextTick(() => {
        this.handleTableTitleResize();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.e6-title {
  border-top: 1px solid #dcdfe6;
  padding: 10px 15px;
  box-sizing: border-box;
  font-size: 16px;
  color: #46bfea;
  position: absolute;
  left: 0px;
}
</style>
