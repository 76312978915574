<!--
 * @Description: 新增/编辑调拨申请弹框
 * @Author: ChenXueLin
 * @Date: 2021-11-02 20:33:56
 * @LastEditTime: 2023-03-21 11:54:53
 * @LastEditors: ChenXueLin
-->
<template>
  <div :class="[detailVisible ? 'dialogMask' : 'hiddenMask']">
    <el-dialog
      title="调拨申请详情"
      :visible.sync="detailVisible"
      :close-on-click-modal="false"
      width="75%"
      v-loading="editDialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="custom-dialog"
      :before-close="closeDialog"
      :modal-append-to-body="false"
      :modal="false"
    >
      <el-form label-width="90px" ref="addForm" :model="addForm" :inline="true">
        <el-row :gutter="24">
          <!-- 调拨申请显示 -->
          <div v-if="dialogType == 1">
            <el-col :span="12">
              <el-form-item label="调入方主体:">
                {{ addForm.inCompanyName }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="调出方主体:">
                {{ addForm.outCompanyName }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="入库仓:">
                {{ addForm.inWarehouseName }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="调拨原因:">
                {{ addForm.reasonName }}
              </el-form-item>
            </el-col>
          </div>
          <!-- 工程调拨申请 -->
          <div v-if="dialogType == 2">
            <el-col :span="24">
              <el-form-item label="调拨类型:" prop="applyType">
                <el-radio-group v-model="addForm.applyType" disabled>
                  <el-radio
                    :label="item.codeId"
                    v-for="(item, index) in allocationApplyTypeList"
                    :key="index"
                    >{{ item.codeName }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="调出方主体:">
                {{ addForm.outCompanyName }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="调入方主体:">
                {{ addForm.inCompanyName }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="调出工程师:">
                {{ addForm.outEngineerName }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="调入工程师:">
                {{ addForm.inEngineerName }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="出库仓:">
                {{ addForm.outWarehouseName }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="入库仓:">
                {{ addForm.inWarehouseName }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="调拨原因:">
                {{ addForm.reasonName }}
              </el-form-item>
            </el-col>
          </div>
          <el-col :span="12">
            <el-form-item label="" prop="deliveryType">
              <el-checkbox
                v-model="addForm.deliveryType"
                :true-label="1"
                :false-label="2"
                disabled
              >
                自提(无实物物流时,请勾选自提)</el-checkbox
              >
            </el-form-item>
          </el-col>
          <block v-if="addForm.deliveryType == 2">
            <el-col :span="12">
              <el-form-item label="联系人:">
                {{ addForm.contactName }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电话:">
                {{ addForm.contactPhone }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="省市区地址:">
                {{ addForm.defaultCity }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="详细地址:">
                {{ addForm.address }}
              </el-form-item>
            </el-col>
          </block>
          <el-col :span="24">
            <el-form-item label="备注:" prop="remark">
              {{ addForm.remark }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template>
        <div class="edit-content" style="margin-bottom: 20px">
          <el-table ref="table" highlight-current-row :data="tableData">
            <el-table-column
              show-overflow-tooltip
              v-for="(column, index) in columnData"
              :key="index"
              :prop="column.fieldName"
              :label="column.fieldLabel"
              :min-width="column.width"
              :fixed="column.fixed"
              :align="column.align"
              header-align="center"
            >
            </el-table-column>
          </el-table>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import {
  findDownList,
  getEngineeringApplyDetail,
  getAllocationApplyDetail
} from "@/api";

import { printError } from "@/utils/util";

export default {
  name: "addTransferDialog",
  components: {},
  data() {
    return {
      editDialogLoading: false,
      columnData: [
        {
          fieldName: "outWarehouseName",
          display: true,
          fieldLabel: "调出仓库",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "materialName",
          display: true,
          fieldLabel: "物料名称",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "materialNo",
          display: true,
          fieldLabel: "物料编码",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "materialNum",
          display: true,
          fieldLabel: "物料数量",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipNoList",
          display: true,
          fieldLabel: "设备号",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ],
      allocationApplyTypeList: [], //调拨类型
      addForm: {
        applyType: "", //调拨类型
        inCompanyNo: "", //调入方主体
        inCompanyName: "", //调入方主体名称
        outCompanyNo: "", //调出方主体
        outCompanyName: "", //调出方主体名称
        reason: "", //调拨原因
        outWarehouseNo: "", //出库仓
        outWarehouseName: "", //出仓名称
        outWarehouseId: "", //出库仓库ID
        inWarehouseNo: "", //入库仓
        inWarehouseName: "", //入仓名称
        contactName: "", //联系人
        contactPhone: "", //电话
        defaultCity: "", //默认选中地址
        provinceId: "",
        provinceName: "",
        cityName: "",
        cityId: "",
        countyName: "",
        countyId: "",
        streetName: "",
        streetId: "",
        address: "", //地址
        remark: "", //备注
        inEngineerId: "",
        outEngineerId: "",
        deliveryType: 2
      },

      tableData: []
    };
  },
  props: {
    detailVisible: {
      type: Boolean,
      default: false
    },
    //编辑时的id
    id: {
      type: [String, Number]
    },
    //弹框类型1调拨申请2工程师调拨申请
    dialogType: {
      type: [String, Number],
      default: "1"
    }
  },
  mixins: [base],
  computed: {},
  created() {},
  watch: {
    //弹框显示
    detailVisible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.initData();
          if (this.dialogType == 1) {
            this.getAllocationApplyDetailReq();
          } else {
            this.getEngineeringApplyDetailReq();
          }
        }
      }
    }
  },
  methods: {
    async initData() {
      let promiseList = [findDownList(["allocationApplyType"])];
      let [enumRes] = await Promise.all(promiseList);
      //调拨类型下拉框
      this.allocationApplyTypeList = this.getFreezeResponse(enumRes, {
        path: "data.allocationApplyType"
      });
    },
    //获取调拨详情
    async getAllocationApplyDetailReq() {
      try {
        this.editDialogLoading = true;
        let res = await getAllocationApplyDetail({
          id: this.id
        });
        this.handleDetailData(_.cloneDeep(res.data));
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    //获取工程师调拨详情
    async getEngineeringApplyDetailReq() {
      try {
        this.editDialogLoading = true;
        let res = await getEngineeringApplyDetail({
          id: this.id
        });
        this.handleDetailData(_.cloneDeep(res.data));
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    //获取详情后处理数据回显
    handleDetailData(addForm) {
      let newAddForm = { ...addForm };
      this.tableData = newAddForm.saveReqVOList;
      newAddForm.applyType = addForm.applyType * 1;
      newAddForm.applyStatus = addForm.applyStatus * 1;
      newAddForm.reason = addForm.reason * 1;
      newAddForm.outEngineerId = newAddForm.outEngineerId
        ? newAddForm.outEngineerId
        : "";
      newAddForm.inEngineerId = newAddForm.inEngineerId
        ? newAddForm.inEngineerId
        : "";
      let provinceName = addForm.provinceName ? addForm.provinceName : "";
      let cityName = addForm.cityName ? addForm.cityName : "";
      let countyName = addForm.countyName ? addForm.countyName : "";
      let streetName = addForm.streetName ? addForm.streetName : "";
      newAddForm.defaultCity =
        provinceName + cityName + countyName + streetName;
      this.addForm = newAddForm;
    },
    //关闭弹框
    closeDialog() {
      this.addForm = {
        applyType: "", //调拨类型
        outWarehouseId: "",
        inCompanyNo: "", //调入方主体
        inCompanyName: "", //调入方主体名称
        outCompanyNo: "", //调出方主体
        outCompanyName: "", //调出方主体名称
        reason: "", //调拨原因
        outWarehouseNo: "", //出库仓
        outWarehouseName: "", //出仓名称
        inWarehouseNo: "", //入库仓
        inWarehouseName: "", //入仓名称
        contactName: "", //联系人
        contactPhone: "", //电话
        provinceId: "",
        provinceName: "",
        cityName: "",
        cityId: "",
        countyName: "",
        countyId: "",
        streetName: "",
        streetId: "",
        address: "", //地址
        inEngineerId: "",
        outEngineerId: "",
        defaultCity: "",
        remark: "" //备注
      };
      this.tableData = [];
      this.$refs.addForm.resetFields();
      this.$emit("update:detailVisible", false);
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 20px;
    max-height: 600px;
    overflow-y: auto;
    box-sizing: border-box;
    // 公司主体下拉框宽度设置
    .popperClass {
      width: 320px !important;
      text-align: left;
    }
    .el-form {
      .el-input {
        width: 220px;
      }
      .el-textarea {
        width: 500px;
      }
    }

    .el-form {
      .el-table {
        .el-form-item {
          margin-bottom: 12px;
          .e6-vr-select {
            width: 280px;
            .el-input {
              width: 270px;
            }
          }
          .el-input {
            width: 140px;
          }
        }
      }
    }
  }
  .associated-info {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
  }
}
.search-corpName {
  width: 280px;
}
</style>
