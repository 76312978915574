<!--
 * @Description: 物流信息
 * @Author: LiangYiNing
 * @Date: 2022-08-22 17:09:49
 * @LastEditTime: 2023-02-21 14:57:46
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    title="物流信息"
    :visible.sync="logisticsVisible"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    width="800px"
    v-loading="editDialogLoading"
    :element-loading-background="loadingBackground"
    custom-class="custom-dialog"
    :before-close="closeDialog"
  >
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <div class="step-and-progress__content">
        <el-steps :active="active" align-center>
          <el-step
            class="custom-step"
            v-for="(node, index) in stepList"
            :key="`${index}e3-step-progress`"
          >
            <template slot="icon">
              <div class="step-content__finish">
                <span
                  class="e3-icon-finish"
                  :class="[active >= index ? node.activeClass : node.class]"
                ></span>
              </div>
            </template>
            <template slot="title">
              <div class="step-and-progress__title">{{ node.title }}</div>
            </template>
          </el-step>
        </el-steps>
      </div>
      <div class="time-line__content">
        <div class="baseInfo-box">
          <div class="edit-title">
            {{ `${this.expressNo}-${this.expressName}` }}
          </div>
        </div>
        <el-timeline>
          <!-- :reverse="reverse" -->
          <el-timeline-item
            placement="top"
            v-for="(activity, index) in expressMessages"
            :key="index"
            :timestamp="activity.expressTimeStr"
            :class="{ activeTimeLine: index === 0 }"
          >
            {{ activity.expressMessage }}
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import { getExpressInfo } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "LogisticsInformation",
  mixins: [base],
  data() {
    return {
      editDialogLoading: false,
      loading: false,
      expressNo: "", // 物流单号
      receivePhoneNo: "", // 收件人联系方式
      receiveAddress: "", // 收件地址
      expressStatus: "", // 物流状态
      expressName: "",
      expressMessages: [],
    };
  },
  computed: {
    active() {
      let active = -1;
      switch (this.expressStatus) {
        //揽收
        case 0:
          active = 0;
          break;
        //运输中
        case 1:
          active = 1;
          break;
        //派送
        case 2:
        case 4:
        case 5:
          active = 2;
          break;
        //签收
        case 3:
        case 6:
        case 7:
          active = 3;
          break;
      }
      return active;
    },
  },
  props: {
    stepList: {
      type: Array,
      default: () => [
        {
          title: "仓库接单",
          percentage: 100,
          class: "missedOrders",
          activeClass: "receivedOrders",
        },
        {
          title: "运输",
          percentage: 25,
          class: "notTransported",
          activeClass: "transporting",
        },
        {
          title: "派送",
          percentage: 0,
          class: "notDelivered",
          activeClass: "inDelivery",
        },
        {
          title: "签收",
          percentage: 0,
          class: "notSigned",
          activeClass: "signed",
        },
      ],
    },
    logisticsVisible: {
      type: Boolean,
      default: false,
    },
    outWarehouseId: {
      type: [Number, String],
      default: "",
    },
  },
  watch: {
    logisticsVisible: {
      immediate: true,
      handler(value) {
        if (value) {
          this.getExpressInfo();
        }
      },
    },
  },
  methods: {
    async getExpressInfo() {
      try {
        this.loading = true;
        let res = await getExpressInfo({
          outWarehouseId: this.outWarehouseId,
        });
        if (res.code === "OK") {
          let data = res.data;
          this.expressMessages = data.details;
          if (!this.expressMessages.length) {
            this.expressMessages.push({ expressMessage: "未查询到物流信息" });
          }
          this.receivePhoneNo = data.phoneNo;
          this.receiveAddress = data.receiverAddress;
          this.expressStatus = data.details.length
            ? data.details[0].status
            : "";
          this.expressNo = data.expressNo;
          this.expressName = data.expressCompanyName;
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },

    //关闭弹框
    closeDialog() {
      this.expressNo = ""; // 物流单号
      this.receivePhoneNo = ""; // 收件人联系方式
      this.receiveAddress = ""; // 收件地址
      this.expressStatus = ""; // 物流状态
      this.expressName = "";
      this.expressMessages = [];
      this.$emit("update:logisticsVisible", false);
    },
  },
};
</script>

<style lang="scss" scoped>
$step-icon-height: 100px;
$step-icon-color-finish: rgb(131, 150, 181);
$step-icon-color: rgb(229, 232, 237);
$step-icon-border-width: 5px;
.edit-title {
  line-height: 60px;
  font-weight: bold;
}
/deep/.step-and-progress__content {
  height: 120px;
  padding: 30px 20px;
  background: rgb(250, 252, 251);
  .el-step.is-horizontal .el-step__line {
    top: $step-icon-height / 2;
    overflow: hidden;
  }
  .el-step__icon.is-text {
    border: none;
    background: rgba(250, 252, 251, 1);
    border-radius: 4px;
    height: $step-icon-height;
    width: 140px;
    position: relative;
    margin: 2px;
  }
  .step-content__finish {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    font-size: 36px;
    font-weight: bold;
    line-height: $step-icon-height - $step-icon-border-width * 2;
  }
  .el-step__head.is-finish {
    .el-step__line {
      height: 0;
      border-top: $step-icon-color-finish dotted 6px;
    }
  }
  .el-step__head .el-step__line {
    background-color: rgba($color: #000000, $alpha: 0);
    height: 0;
    border-top: $step-icon-color dotted 6px;
  }
  .e3-icon-finish {
    height: 100%;
    width: 100%;
    display: inline-block;
    background-position: center center;
  }
  .e3-progress {
    .el-progress__text {
      font-size: 16px !important;
      font-weight: bold;
    }
  }
  .step-and-progress__title {
    color: #606266;
    font-weight: normal;
  }
}
.inDelivery {
  background: url("../../assets/images/order/inDelivery.png")
    no-repeat;
}
.notDelivered {
  background: url("../../assets/images/order/notDelivered.png")
    no-repeat;
}
.receivedOrders {
  background: url("../../assets/images/order/receivedOrders.png")
    no-repeat;
}
.missedOrders {
  background: url("../../assets/images/order/missedOrders.png") no-repeat;
}
.signed {
  background: url("../../assets/images/order/signed.png") no-repeat;
}
.notSigned {
  background: url("../../assets/images/order/notSigned.png") no-repeat;
}
.transporting {
  background: url("../../assets/images/order/transporting.png") no-repeat;
}
.notTransported {
  background: url("../../assets/images/order/notTransported.png") no-repeat;
}

/deep/ .time-line__content {
  margin: 20px 100px 20px 220px;
  height: calc(100% - 120px);
  position: relative;
  .el-timeline-item__timestamp.is-top {
    position: absolute;
    text-align: right;
    left: -200px;
    width: 180px;
    padding: 0;
    margin: 0;
  }
  .activeTimeLine {
    font-weight: bold;
    .el-timeline-item__timestamp {
      color: #303133;
    }
    .el-timeline-item__node {
      background: rgb(69, 190, 233);
      box-shadow: rgba(69, 190, 233, 0.3) 0px 0px 0px 3px;
    }
  }
}
/deep/.custom-dialog {
  .el-dialog__body {
    max-height: 600px;
    overflow-y: auto;
  }
}
</style>
