<!--
 * @Description: 拆卸,维修,巡检审核弹框
 * @Author: ChenXueLin
 * @Date: 2021-11-22 17:13:23
 * @LastEditTime: 2023-03-07 15:31:06
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <!-- 补录信息 -->
    <el-dialog
      v-dialogDrag
      :title="dialogType == 2 ? '维修' : '补录信息'"
      :visible.sync="auditVisible"
      width="1100px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      v-loading="dialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="add-concat-dialog"
    >
      <div class="audit-content">
        <el-form :inline="true" label-width="100px">
          <el-form-item label="场景名称">
            {{ basicInfo.sceneName }}
          </el-form-item>
          <el-form-item label="场景类型">
            {{ basicInfo.sceneTypeName }}
          </el-form-item>
        </el-form>
        <el-form
          ref="auditForm"
          label-width="130px"
          :model="auditForm"
          :rules="auditFormRules"
          :inline="true"
        >
          <el-form-item label="工程师" prop="engineerrpName">
            <el-input
              v-model.trim="basicInfo.engineerrpName"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="作业时间" prop="finishTime">
            <el-date-picker
              v-model="auditForm.finishTime"
              type="datetime"
              placeholder="选择日期"
              value-format="timestamp"
              popper-class="special"
              :picker-options="pickerOptions1"
              :disabled="basicInfo.fixDateStr ? true : false"
            ></el-date-picker>
          </el-form-item>

          <el-form-item
            label="客责确认"
            prop="engineerResponsibilityId"
            v-if="basicInfo.taskSecondClass == 10"
          >
            <e6-vr-select
              v-model="auditForm.engineerResponsibilityId"
              :data="engineerResponsibilityList"
              placeholder="客责确认"
              title="客责确认"
              :props="{
                id: 'codeValue',
                label: 'codeName'
              }"
              clearable
              @change="changeEngineerResponsibility"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item
            label="故障原因"
            prop="solutionCode"
            v-if="basicInfo.taskSecondClass == 10"
          >
            <e6-select
              v-model="auditForm.solutionCode"
              :dataList="solutionList"
              :filterable="true"
              :issingleleaf="true"
              :slotTemplate="'tree'"
              :multiple="false"
              ref="t_select"
              @multipleSelect="multipleSelect"
            ></e6-select>
          </el-form-item>
          <el-form-item
            label="故障原因及处理措施"
            prop="faultCause"
            v-if="basicInfo.taskSecondClass == 10"
          >
            <el-cascader
              :props="propsOptions"
              clearable
              v-model="auditForm.faultCause"
              title
              filterable
              style="width:300px"
              ref="cascader"
              @change="changeCascader"
            ></el-cascader>
          </el-form-item>
        </el-form>
        <!-- 设备明细 -->
        <template>
          <div class="detail-title">设备明细</div>
          <el-table :data="equipDetailTableData" max-height="350">
            <el-table-column
              show-overflow-tooltip
              v-for="(column, index) in columnData"
              :key="index"
              :prop="column.fieldName"
              :label="column.fieldLabel"
              :min-width="column.width"
              :fixed="column.fixed"
              :align="column.align"
              header-align="center"
            >
              <template slot-scope="{ row }">
                <span v-if="column.fieldName == 'equipOp'">
                  <e6-vr-select
                    v-model="row.equipOp"
                    :data="equipOpList"
                    placeholder="设备操作"
                    title="设备操作"
                    :props="{
                      id: 'codeValue',
                      label: 'codeName'
                    }"
                    :disabled="
                      row.isReadOnly == 1 || row.isReductionMaterial == 2
                    "
                    @change="handleEquip(row)"
                  ></e6-vr-select>
                </span>
                <span
                  v-else-if="
                    column.fieldName == 'dismantleStatus' &&
                      (row.equipOp == 2 ||
                        (basicInfo.taskSecondClass == 12 && row.isNeedRecycle))
                  "
                >
                  <e6-vr-select
                    v-model="row.dismantleStatus"
                    :data="dismantleStatusList"
                    placeholder="原设备去向"
                    title="原设备去向"
                    :props="{
                      id: 'codeValue',
                      label: 'codeName'
                    }"
                    :disabled="row.isReadOnly == 1"
                  ></e6-vr-select>
                </span>
                <span v-else-if="column.fieldName == 'recycleTagNo'">
                  <el-input
                    v-model.trim="row.recycleTagNo"
                    placeholder="请输入原设备编号"
                    v-if="
                      row.isNeedRecycle &&
                        (row.dismantleStatus == 1 || row.dismantleStatus == 3)
                    "
                    :disabled="row.isReadOnly == 1"
                    @blur="getIsisSendBack(row)"
                  ></el-input>
                </span>
                <span v-else-if="column.fieldName == 'isSendBack'">
                  <span v-if="row.recycleTagNo">
                    {{ row.isSendBack == 1 ? "需要" : "不需要" }}</span
                  >
                </span>
                <span
                  v-else-if="
                    column.fieldName == 'abnormalReason' &&
                      row.isNeedRecycle &&
                      (row.dismantleStatus == 2 || row.dismantleStatus == 4)
                  "
                >
                  <el-input
                    v-model.trim="row.abnormalReason"
                    placeholder="请输入异常原因"
                    :disabled="row.isReadOnly == 1"
                  ></el-input>
                </span>
                <span
                  v-else-if="
                    column.fieldName == 'fileUrl' &&
                      row.isNeedRecycle &&
                      row.dismantleStatus == 4
                  "
                >
                  <uploadOBS
                    :fileType="2"
                    :limitSize="10"
                    :showFileList="false"
                    :showBtnType="0"
                    :isShowIcon="false"
                    :canRemove="false"
                    :limit="1"
                    :limitConfig="['jpeg', 'png', 'bmp', 'gif', 'jpg']"
                    @success="
                      res => {
                        handleImgUpload(res, row);
                      }
                    "
                  >
                    <div slot="preview">
                      <img
                        v-if="
                          row.fileUrl ||
                            (row.fileList &&
                              row.fileList.length &&
                              row.fileList[0].url)
                        "
                        :src="
                          row.fileUrl ||
                            (row.fileList &&
                              row.fileList.length &&
                              row.fileList[0].url)
                        "
                        style="width:36px;"
                        :preview-src-list="row.fileUrl"
                      />
                      <i
                        v-else
                        class="el-icon-upload"
                        style="font-size:30px"
                      ></i>
                    </div>
                  </uploadOBS>
                </span>
                <span
                  v-else-if="
                    column.fieldName == 'cardOp' &&
                      row.equType == 1 &&
                      row.cardOp
                  "
                >
                  <e6-vr-select
                    v-model="row.cardOp"
                    :data="row.oldSimNo ? cardOpList1 : cardOpList"
                    placeholder="卡操作"
                    title="卡操作"
                    :props="{
                      id: 'codeValue',
                      label: 'codeName'
                    }"
                    @change="
                      (val, node) => {
                        handleCard(val, node, row);
                      }
                    "
                    :disabled="row.isReadOnly == 1"
                  ></e6-vr-select>
                </span>
                <span
                  v-else-if="column.fieldName == 'cardOp' && row.equType != 1"
                >
                </span>
                <span
                  v-else-if="
                    column.fieldName == 'simNo' &&
                      (row.cardOp == 3 || row.cardOp == 2)
                  "
                >
                  <el-input
                    v-model.trim="row.simNo"
                    placeholder="请输入SIM卡号"
                    v-if="row.cardOp == 3 || row.cardOp == 2"
                    :disabled="row.isReadOnly == 1 || row.disabledSim"
                  ></el-input>
                </span>
                <span
                  v-else-if="
                    column.fieldName === 'commids' &&
                      row.equType == 1 &&
                      (row.equipOp == 2 || row.equipOp == 3) &&
                      (basicInfo.sourceOrganization == 1 ||
                        (basicInfo.sourceOrganization == 2 && !row.imei))
                  "
                >
                  <el-input
                    v-model.trim="row.commids"
                    :disabled="
                      row.isReadOnly == 1 ||
                      row.disabledCommids ||
                      row.isAutoObtain ||
                      basicInfo.taskSecondClass == 12
                        ? true
                        : false
                    "
                    maxlength="20"
                  ></el-input>
                  <!--   @input="row.commids = row.commids.replace(/[^0-9-]+/, '')" -->
                </span>
                <span v-else>{{ row[column.fieldName] }}</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <!-- 作业图片 -->
        <template>
          <div class="title">上传凭证</div>
          <div class="img-content">
            <div class="img-item" v-for="(item, index) in imgList" :key="index">
              <uploadOBS
                class="avatar-uploader"
                :fileType="2"
                :limitSize="10"
                :showFileList="false"
                :showBtnType="0"
                :canRemove="false"
                :limitConfig="['jpeg', 'png', 'bmp', 'gif', 'jpg']"
                @success="
                  res => {
                    handleAvatarSuccess(res, item);
                  }
                "
              >
                <div slot="preview">
                  <img
                    v-if="item.fileUrl"
                    :src="item.fileUrl"
                    class="avatar"
                    :preview-src-list="item.fileUrl"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </div>
              </uploadOBS>
              <div class="img-title">{{ item.photoPositionName }}</div>
            </div>
          </div>
        </template>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
        <el-button
          type="primary"
          @click="sendBack"
          v-if="!basicInfo.fixDate && basicInfo.itemStatus == 7"
          >回到未分配</el-button
        >
      </div>
    </el-dialog>
    <!-- 更换设备弹框新 -->
    <new-change-equip
      :changeEquipVisible="changeEquipVisible"
      @close="close"
      :equipRow="equipRow"
      :basicInfo="basicInfo"
      @updateEquip="updateEquip"
      :secondClassName="equipRow.secondClassName"
      :thirdClassName="equipRow.thirdClassName"
    ></new-change-equip>
    <!-- 添加费用 -->
    <add-equip-fee
      :addFeeVisible="addFeeVisible"
      @closeDialog="closeDialog"
      @updateFee="updateFee"
      :terminalId="equipRow.terminalId"
    ></add-equip-fee>
    <!-- 选择sim卡 -->
    <select-sim
      :engineerrpId="basicInfo.engineerrpId"
      :simVisible="simVisible"
      :equipRow="equipRow"
      @closeSim="closeSim"
      @selectSim="selectSim"
      v-if="simVisible"
    ></select-sim>
  </div>
</template>

<script>
import base from "@/mixins/base";
import uploadOBS from "@/components/uploadOBS/uploadOBS";
import {
  findDownList,
  equipUpdateOwnerAndHave,
  otherTaskCollection,
  querySendBack,
  checkAllEquip,
  queryRepairSolutionList,
  getEquipBindInfo,
  getFaultClassByParent
} from "@/api";
import { printError } from "@/utils/util";
import dialogMixins from "../mixins/index";
import addEquipFee from "../addEquipFee.vue";
import newChangeEquip from "../newChangeEquip.vue";
import selectSim from "../selectSim.vue";
export default {
  name: "newOtherDialog",
  components: {
    selectSim,
    addEquipFee,
    uploadOBS,
    newChangeEquip
  },
  data() {
    return {
      columnData: [
        {
          fieldName: "equipOp",
          fieldLabel: "设备操作",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "dismantleStatus",
          display: false,
          fieldLabel: "原设备去向",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "recycleTagNo",
          display: false,
          fieldLabel: "原设备编号",
          width: 160,
          // fixed: true,
          align: "center"
        },
        {
          fieldName: "isSendBack",
          display: false,
          fieldLabel: "原设备是否需要寄回",
          width: 160,
          // fixed: true,
          align: "center"
        },
        {
          fieldName: "abnormalReason",
          display: false,
          fieldLabel: "异常原因",
          width: 160,
          // fixed: true,
          align: "center"
        },
        {
          fieldName: "fileUrl",
          display: false,
          fieldLabel: "异常凭证",
          width: 160,
          // fixed: true,
          align: "center"
        },
        {
          fieldName: "cardOp",
          fieldLabel: "卡操作",
          width: 160,
          align: "center"
        },
        {
          fieldName: "commids",
          fieldLabel: "中心识别码",
          width: 140,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "simNo",
          fieldLabel: "新SIM卡",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "oldSimNo",
          fieldLabel: "旧SIM卡",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "thirdClassName",
          fieldLabel: "商品名称",
          width: 140,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "firstClassName",
          fieldLabel: "商品分类",
          width: 140,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "secondClassName",
          fieldLabel: "商品开票名称",
          width: 140,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equCombination",
          fieldLabel: "安装组合",
          width: 140,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipCode",
          fieldLabel: "新设备编号",
          width: 140,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "oldEquipCode",
          fieldLabel: "旧设备编号",
          width: 140,
          fixed: false,
          align: "center"
        }
      ],
      pickerOptions1: {
        disabledDate(time) {
          let currentDate = new Date();
          // 将当前月份减1，得到上个月的日期
          currentDate.setMonth(currentDate.getMonth());
          // 将日期设置为上个月的最后一天
          currentDate.setDate(0);
          // 将时间设置为上个月最后一天的最后一秒
          currentDate.setSeconds(59);
          currentDate.setMinutes(59);
          currentDate.setHours(23);
          const firstDay = new Date(currentDate).getTime();
          var now = new Date();
          // 将月份设置为下个月，日期设置为0，即本月最后一天
          now.setMonth(now.getMonth() + 1);
          now.setDate(0);
          // 将时间设置为最后一秒
          now.setSeconds(59);
          now.setMinutes(59);
          now.setHours(23);
          const lastDay = new Date(now).getTime();
          let timeStamp = moment(time).valueOf();
          // 创建一个新的Date对象，默认为当前日期和时间
          return timeStamp <= firstDay || timeStamp > lastDay;
        }
      },
      dialogLoading: false,
      auditForm: {
        quesReason: "", //诊断问题
        quesReasonId: "",
        mainBodyId: "",
        mainBody: "",
        solution: "", //处理方案
        solutionId: "",
        faultClass: "",
        faultClassId: "",
        faultReason: "",
        faultReasonId: "",
        finishTime: "", //作业时间
        solutionCode: "",
        solutionName: "",
        faultCause: [],
        engineerResponsibilityId: "",
        engineerResponsibility: ""
      },
      engineerResponsibilityList: [], //客责
      solutionList: [], //故障原因
      quesReasonList: [], //故障现象
      measureList: [],
      equipRow: {},
      simVisible: false,
      auditFormRules: {
        finishTime: [
          {
            required: true,
            message: "请选择作业时间",
            trigger: ["blur", "change"]
          }
        ],
        faultCause: [
          {
            required: true,
            message: "请选择故障及处理原因",
            trigger: ["blur", "change"]
          }
        ],
        engineerResponsibilityId: [
          {
            required: true,
            message: "请选择客责确认",
            trigger: ["blur", "change"]
          }
        ]
      },
      equipOpList: [], //设备操作类型
      equipOpList1: [], //维修/巡检单的设备操作类型
      equipOpList2: [], //拆卸单的设备操作类型
      cardOpList: [], //卡操作类型
      cardOpList1: [],
      dismantleStatusList: [], //原设备去向
      // simList: [], //工程师名下sim卡
      /*******设备明细********/
      /******* 换卡*******/
      changeCardVisible: false,
      /******换机*******/
      handleType: "", //设备操作类型
      changeEquipVisible: false,
      /*******添加费用********/
      addFeeVisible: false,
      propsOptions: {
        // checkStrictly: true, //是否可以选择树干节点作为选项
        lazy: true,
        async lazyLoad(node, resolve) {
          const { level } = node;
          let nodeId = level == 0 ? 0 : node.value;
          let params = {
            parentFaultType: level,
            faultType: level + 1,
            parentId: nodeId
          };
          const res = await getFaultClassByParent(params); // 掉接口
          let list = [];
          res.data.map(item => {
            let obj = {
              value: item.faultClassId,
              label: item.faultName,
              leaf: level >= 4
            };
            list.push(obj);
          });
          resolve(list);
        }
      },
      pathNodes: [],
      contractFileList: [] //附件列表
    };
  },
  props: ["basicInfo", "auditVisible", "dialogType"],
  mixins: [base, dialogMixins],
  computed: {},
  watch: {
    auditVisible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.initData();
          this.getInstallDetail();
          this.getCredentialReq();
          this.auditForm.finishTime = this.basicInfo.fixDateStr
            ? this.basicInfo.fixDateStr
            : "";
          if (this.basicInfo.taskSecondClass == 10) {
            this.auditForm.quesReason = this.basicInfo.quesReason
              ? this.basicInfo.quesReason
              : "";
            this.auditForm.solution = this.basicInfo.solution
              ? this.basicInfo.solution
              : "";
            this.auditForm.engineerResponsibilityId = this.basicInfo.engineerResponsibilityId.toString();
            //客责
            this.auditForm.engineerResponsibility = this.basicInfo.engineerResponsibility;
            //客责
            this.auditForm.mainBodyId = this.basicInfo.mainBodyId
              ? this.basicInfo.mainBodyId
              : ""; //主体
            this.auditForm.mainBody = this.basicInfo.mainBodyId
              ? this.basicInfo.mainBody
              : ""; //主体
            this.auditForm.quesReasonId = this.basicInfo.quesReasonId
              ? this.basicInfo.quesReasonId
              : ""; //故障现象
            this.auditForm.quesReason = this.basicInfo.quesReasonId
              ? this.basicInfo.quesReason
              : ""; //故障现象
            this.auditForm.solutionId = this.basicInfo.solutionId
              ? this.basicInfo.solutionId
              : ""; //处理措施
            this.auditForm.solution = this.basicInfo.solutionId
              ? this.basicInfo.solution
              : ""; //处理措施
            this.auditForm.faultClass = this.basicInfo.faultClass
              ? this.basicInfo.faultClass
              : "";
            this.auditForm.faultClassId = this.basicInfo.faultClassId
              ? this.basicInfo.faultClassId
              : "";
            this.auditForm.faultReason = this.basicInfo.faultReason
              ? this.basicInfo.faultReason
              : "";
            this.auditForm.faultReasonId = this.basicInfo.faultReasonId
              ? this.basicInfo.faultReasonId
              : "";
            this.auditForm.faultCause = [
              this.basicInfo.mainBodyId,
              this.basicInfo.quesReasonId,
              this.basicInfo.faultClassId,
              this.basicInfo.solutionId,
              this.basicInfo.faultReasonId
            ];
          }
        }
      }
    }
  },
  created() {},
  methods: {
    //获取工程师所有信息
    async initData() {
      try {
        this.dialogLoading = true;
        let promiseList = [
          findDownList([
            "equipOp",
            "cardOp",
            "equipOpNew",
            "disassembleEquipOp",
            "dismantleStatus",
            "engineerResponsibility"
          ]), //获取工程师名下sim卡
          // engineerSimList({
          //   id: this.basicInfo.engineerrpId
          // }),
          queryRepairSolutionList()
        ];
        let [res, solutionRes] = await Promise.all(promiseList);
        //客责
        this.engineerResponsibilityList = this.getFreezeResponse(res, {
          path: "data.engineerResponsibility"
        });
        //设备操作类型
        this.equipOpList1 = this.getFreezeResponse(res, {
          path: "data.equipOp"
        });
        //灰度客户设备操作类型
        this.equipOpList3 = this.getFreezeResponse(res, {
          path: "data.equipOpNew"
        });
        //设备操作类型
        this.equipOpList2 = this.getFreezeResponse(res, {
          path: "data.disassembleEquipOp"
        });
        if (this.basicInfo.taskSecondClass == 12) {
          this.equipOpList = this.equipOpList2;
        } else {
          this.equipOpList = this.equipOpList3;
        }
        //卡操作类型
        this.cardOpList = res.data.cardOp;
        this.cardOpList.map(item => {
          if (item.codeName != "装卡") {
            this.cardOpList1.push(item);
          }
        });
        this.auditForm.solutionCode = this.basicInfo.solutionCode
          ? this.basicInfo.solutionCode.toString()
          : "";
        //设备去向
        this.dismantleStatusList = this.getFreezeResponse(res, {
          path: "data.dismantleStatus"
        });
        //获取工程师名下sim卡
        // this.simList = this.getFreezeResponse(simRes, {
        //   path: "data"
        // });
        //故障原因
        let solutionList = solutionRes.data;
        solutionList.map(item => {
          item.id = item.parentCode;
          item.pid = 0;
          item.disabled = false;
          item.label = item.parentName;
          item.children = item.solutionDetailDTOS;
          item.bindSimNo = item.simNo;
          item.bindSimId = item.simId;
          item.bindIsSelfOwn = item.isSelfOwn;
          item.children.map(each => {
            each.pid = item.id;
            each.id = each.solutionCode;
            each.label = each.solutionName;
            each.disabled = false;
          });
        });
        this.solutionList = solutionList;
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    async getIsisSendBack(row) {
      try {
        let res = await querySendBack({
          recycleTagNoList: [row.recycleTagNo]
        });
        if (res.code == "OK") {
          row.isSendBack = res.data[0].isSendBack;
        }
      } catch (error) {
        this.dialogLoading = false;
        printError(error);
      }
    },
    //选中故障及处理原因
    changeCascader() {
      let pathNodes = this.$refs.cascader.getCheckedNodes()[0].pathNodes;
      this.auditForm.mainBody = pathNodes[0].label;
      this.auditForm.mainBodyId = pathNodes[0].value;
      this.auditForm.quesReason = pathNodes[1].label;
      this.auditForm.quesReasonId = pathNodes[1].value;
      this.auditForm.faultClass = pathNodes[2].label;
      this.auditForm.faultClassId = pathNodes[2].value;
      this.auditForm.solution = pathNodes[3].label;
      this.auditForm.solutionId = pathNodes[3].value;
      this.auditForm.faultReason = pathNodes[4].label;
      this.auditForm.faultReasonId = pathNodes[4].value;
    },

    //选择客责
    changeEngineerResponsibility(val, node) {
      this.auditForm.engineerResponsibility = node.codeName;
    },
    //选择故障
    multipleSelect(node) {
      if (node.length) {
        this.auditForm.solutionName = node[0].label;
      }
    },
    //重置form
    resetForm() {
      this.auditForm = {
        quesReason: "", //诊断问题
        quesReasonId: "",
        mainBodyId: "",
        mainBody: "",
        solution: "", //处理方案
        solutionId: "",
        faultClass: "",
        faultClassId: "",
        faultReason: "",
        faultReasonId: "",
        finishTime: "", //作业时间
        solutionCode: "",
        solutionName: "",
        faultCause: [],
        engineerResponsibilityId: "",
        engineerResponsibility: ""
      };
      this.equipDetailTableData = [];
      this.$refs.auditForm.resetFields();
      this.imgList = [];
    },
    //点击确定
    handleConfirm() {
      this.$refs.auditForm.validate(valid => {
        if (valid) {
          let equipDetailTableData = this.equipDetailTableData;
          //主机设备
          let equipTypeList = equipDetailTableData.filter(item => {
            return item.equType == 1;
          });
          //检验sim卡位数
          let simRegx = /^(\d{11}|\d{13})$/;
          if (
            equipTypeList.some(
              item =>
                (item.cardOp == 2 || item.cardOp == 3) &&
                !simRegx.test(item.simNo)
            )
          ) {
            this.$message.warning(
              "SIM卡号只能是由11位或13位纯数字组成，请修改后重新提交"
            );
            return;
          }
          if (
            equipDetailTableData.some(
              item =>
                item.equipOp == 2 &&
                item.isNeedRecycle &&
                (item.dismantleStatus == 1 || item.dismantleStatus == 3) &&
                !item.recycleTagNo
            )
          ) {
            this.$message.warning("回收设备，原设备号必须有值");
            return;
          }
          if (
            equipTypeList.some(
              item =>
                !item.commids &&
                (item.equipOp == 2 || item.equipOp == 3) &&
                (this.basicInfo.sourceOrganization == 1 ||
                  (this.basicInfo.sourceOrganization == 2 && !item.imei))
            )
          ) {
            this.$message.warning("主机的中心识别码不能为空");
            return;
          }
          if (
            this.equipDetailTableData.some(
              item => item.isChangeSim == 1 && item.cardOp == 0
            )
          ) {
            this.$confirm(
              "SIM卡已超1.5年，请点“取消”换卡，无需换卡请点“确定”",
              "提示",
              {
                type: "warning"
              }
            ).then(() => {
              this.$confirm(
                "是否确认提交补录信息，提交后将不能再更改！",
                "补录信息确认",
                {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning"
                }
              )
                .then(() => {
                  if (this.basicInfo.isReportMsg) {
                    this.$confirm(
                      "由于安装物料被更新，需重新上报耗材，若已重新上报请忽略！",
                      "提示",
                      {
                        confirmButtonText: "我知道了",
                        showCancelButton: false,
                        type: "warning"
                      }
                    ).then(() => {
                      this.handleSubmit();
                    });
                  } else {
                    this.handleSubmit();
                  }
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消"
                  });
                });
            });
          } else {
            if (this.basicInfo.isReportMsg) {
              this.$confirm(
                "由于安装物料被更新，需重新上报耗材，若已重新上报请忽略！",
                "提示",
                {
                  confirmButtonText: "我知道了",
                  showCancelButton: false,
                  type: "warning"
                }
              ).then(() => {
                this.handleSubmit();
              });
            } else {
              this.handleSubmit();
            }
          }
        }
      });
    },
    handleSubmit() {
      let msg = this.dialogType == 1 ? "装车信息" : "补录信息";
      this.$confirm(`是否确认提交${msg}，提交后将不能再更改！`, `${msg}确认`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.checkAllEquipReq();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //如果查询的是所有设备，需校验是否可用
    async checkAllEquipReq() {
      try {
        this.dialogLoading = true;
        let equipCodeList = this.equipDetailTableData.map(
          item => item.equipCode
        );
        let res = await checkAllEquip({
          equipCodeList,
          engineerrpId: this.basicInfo.engineerrpId,
          itemId: this.basicInfo.itemId
        });
        if (res.code == "OK") {
          if (res.data.isTips) {
            //需要提示的调用流转接口
            this.$confirm(res.data.tips, "", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            })
              .then(() => {
                this.noEngineerEquipCodeList = res.data.noEngineerEquipCodeList;
                this.wareHouseEquipCodeList = res.data.wareHouseEquipCodeList;
                this.noProjectEquipCodeList = res.data.noProjectEquipCodeList;
                this.otherTaskCollection();
                // this.equipUpdateOwnerAndHaveReq();
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消"
                });
              });
          } else {
            //不需要提示的直接提交
            this.otherTaskCollection();
          }
        }
      } catch (error) {
        this.dialogLoading = false;
        printError(error);
      }
    },
    //设备流转
    async equipUpdateOwnerAndHaveReq() {
      try {
        this.dialogLoading = true;
        let res = await equipUpdateOwnerAndHave({
          noEngineerEquipCodeList: this.noEngineerEquipCodeList,
          wareHouseEquipCodeList: this.wareHouseEquipCodeList,
          noProjectEquipCodeList: this.noProjectEquipCodeList,
          engineerrpId: this.basicInfo.engineerrpId
        });
        if (res.code == "OK") {
          this.otherTaskCollection();
        }
      } catch (error) {
        this.dialogLoading = false;
        printError(error);
      }
    },
    //拆卸、维修、巡检补录信息
    async otherTaskCollection() {
      try {
        this.dialogLoading = true;
        let equipDetailTableData = _.cloneDeep(this.equipDetailTableData);
        let dismantleStatusList = this.dismantleStatusList;
        equipDetailTableData.map(item => {
          item.dismantleStatusName = item.dismantleStatus
            ? dismantleStatusList.find(
                ele => ele.codeValue == item.dismantleStatus
              ).codeName
            : "";
        });
        let res = await otherTaskCollection({
          ...this.auditForm,
          itemId: this.basicInfo.itemId,
          taskSecondClass: this.basicInfo.taskSecondClass,
          supplementEuqReqVOList: equipDetailTableData,
          photoLocationReqVOList: this.imgList,
          versionNumber: this.basicInfo.versionNumber
        });
        if (res.code == "OK") {
          this.$message.success("操作成功");
          this.$emit("refresh");
          this.resetForm();
          this.handleClose();
          // eventBus.$emit("SHOW-DIALOG");
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    handleClose() {
      this.$emit("handleClose", "auditVisible");
    },
    /*****换卡操作*********/
    closeDialog(name) {
      this[name] = false;
    },
    //选中换卡
    handleCard(val, node, row) {
      if (row.cardOp == 3 || row.cardOp == 2) {
        this.equipRow = row;
        // this.simVisible = true;
      } else {
        row.simNo = "";
        row.simId = "";
      }
    },

    /******换机/换新*******/
    //选中换机、换新
    handleEquip(row) {
      if (row.equipOp == 2 || row.equipOp == 3) {
        this.handleType = row.equipOp;
        this.changeEquipVisible = true;
        this.equipRow = row;
      } else {
        if (this.basicInfo.taskSecondClass == 10) {
          row.commids = "";
          this.equipRow = row;
        }
      }
    },
    //原设备去向
    handleDismantleStatus(val, node, row) {
      row.dismantleStatusName = node.codeName;
    },
    //更新设备编号
    updateEquip(newEquip) {
      this.equipRow.equipCode = newEquip.equipNo || newEquip.equipCode;
      this.equipRow.newEquipCode = newEquip.equipNo || newEquip.equipCode;
      this.equipRow.equSource = newEquip.equSource;
      if (this.basicInfo.taskSecondClass == 10) {
        //维修单
        this.equipRow.firstClassId = newEquip.firstClassId;
        this.equipRow.firstClassName = newEquip.firstClassName;
        this.equipRow.secondClassId = newEquip.secondClassId;
        this.equipRow.secondClassName = newEquip.secondClassName;
        this.equipRow.thirdClassId = newEquip.thirdClassId;
        this.equipRow.thirdClassName = newEquip.thirdClassName;
        this.equipRow.materialNo = newEquip.materialNo;
      }
      if (this.equipRow.equType == 1) {
        this.getEquipBindInfoReq(newEquip);
      } else {
        this.changeEquipVisible = false;
      }
    },
    //根据设备编号查询sim
    async getEquipBindInfoReq(newEquip) {
      try {
        let res = await getEquipBindInfo({
          equipCode: newEquip.equipNo || newEquip.equipCode
        });
        if (res.code == "OK") {
          let data = res.data;
          this.equipRow.imei = data.imei;
          if (data.simNo) {
            // if (this.basicInfo.sourceOrganization == 1 && !data.simId) {
            //   this.equipRow.newEquipCode = "";
            //   this.equipRow.equipCode = "";
            //   this.equipRow.simNo = "";
            //   this.equipRow.simId = "";
            //   this.equipRow.isSelfOwn = "";
            //   this.$message.warning("请联系供应链赵小兵在E3 2.0采购入库SIM");
            //   return;
            // } else {
            this.equipRow.simNo = data.simNo;
            this.equipRow.simId = data.simId;
            this.equipRow.isSelfOwn = data.isSelfOwn;
            this.equipRow.disabledSim = true;
            // }
          } else {
            this.equipRow.simNo = "";
            this.equipRow.simId = "";
            this.equipRow.isSelfOwn = "";
            this.equipRow.disabledSim = false;
          }
          //设备上线易流云， 没有IMEI， 没有中心识别码： 需要录入中心识别码
          // 设备上线易流云， 有IMEI， 没有中心识别码：需要录入中心识别码
          // 设备上线易流云， 有IMEI， 有中心识别码： 不需要录入中心识别码
          // 设备上线G7S， 没有IMEI， 没有中心识别码：需要录入中心识别码
          // 设备上线G7S， 有IMEI， 没有中心识别码： 不需要录入中心识别码
          // 设备上线G7S， 有IMEI， 有中心识别码： 不需要录入中心识别码
          // sourceOrganization上线平台（1E6yun，2G7s）
          let sourceOrganization = this.basicInfo.sourceOrganization;
          this.equipRow.commids = data.commIds;
          this.equipRow.centerId = data.commIds;
          this.equipRow.isAutoObtain = data.commIds ? 1 : 0;
          if (sourceOrganization == 1 && !data.commIds) {
            this.equipRow.disabledCommids = false;
          }
          if (sourceOrganization == 1 && data.imei && data.commIds) {
            this.equipRow.disabledCommids = true;
          }
          if (sourceOrganization == 2 && !data.imei && !data.commIds) {
            this.equipRow.disabledCommids = false;
          }
          if (sourceOrganization == 2 && data.imei) {
            this.equipRow.disabledCommids = true;
          }

          this.changeEquipVisible = false;
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //更新费用
    updateFee(feeInfo) {
      this.equipRow.fixMoney = feeInfo.fixMoney; //金额
      this.equipRow.fixCatId = feeInfo.fixCatId; //条目ID
      this.equipRow.priceFrom = feeInfo.priceFrom;
      this.addFeeVisible = false;
    },
    //关闭弹框
    close() {
      this.changeEquipVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.audit-content {
  // margin-top: 20px;
  .title {
    padding-left: 20px;
    box-sizing: border-box;
    // margin-bottom: 15px;
    margin-top: 15px;
  }
  .detail-title {
    padding-left: 20px;
    box-sizing: border-box;
    margin-bottom: 15px;
    &::before {
      content: "*";
      color: #f56c6c;
      margin-right: 4px;
    }
  }
  .img-content {
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
    /deep/ .img-item {
      margin-top: 20px;
      margin-right: 15px;
      .avatar-uploader {
        .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          &:hover {
            border-color: #409eff;
          }
        }
        .avatar-uploader-icon {
          font-size: 28px;
          color: #8c939d;
          width: 110px;
          height: 90px;
          line-height: 90px;
          text-align: center;
        }
        .avatar {
          width: 110px;
          height: 90px;
          display: block;
        }
      }
      .img-title {
        width: 100%;
        text-align: center;
        margin-top: 5px;
      }
    }
  }
}
// /deep/ .table-uploader {
//   width: 70px;
//   height: 70px;
//   .el-upload {
//     width: 70px;
//     height: 70px;
//     display: flex;
//     align-items: center;

//     .el-icon-upload {
//     }
//   }
// }
</style>
