<!--
 * @Description: 设置跟进时间
 * @Author: ChenXueLin
 * @Date: 2021-08-30 11:36:51
 * @LastEditTime: 2022-07-15 16:38:16
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="设置跟进时间"
    :visible="setTimeDialog"
    width="630px"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
    :before-close="handleClose"
    v-loading="loading"
    custom-class="time-dialog"
  >
    <div class="content">
      <el-form
        label-width="90px"
        ref="setTimeForm"
        :model="setTimeForm"
        :rules="setTimeFormRulers"
      >
        <el-form-item label="跟进时间" prop="followDate">
          <el-date-picker
            v-model="setTimeForm.followDate"
            type="datetime"
            placeholder="选择日期"
            value-format="timestamp"
            popper-class="special"
            :picker-options="pickerOptions1"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div class="list">
        <h4 style="margin-bottom:20px">更进记录</h4>
        <div>
          <el-timeline>
            <el-timeline-item
              :timestamp="item.operationDate"
              placement="top"
              v-for="(item, index) in followDateList"
              :key="index"
            >
              <el-card>
                <h4>{{ item.operationUser }}</h4>
                <p style="margin-top:10px;">跟进时间 {{ item.followDate }}</p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </div>

    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { createFollowTime, getTaskFollowDateList } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "setTime",
  components: {},
  data() {
    return {
      loading: false,
      followDateList: [],
      setTimeForm: {
        followDate: "",
      },
      setTimeFormRulers: {
        followDate: [
          {
            required: true,
            message: "请选择跟进时间",
            trigger: ["blur", "change"],
          },
        ],
      },
      pickerOptions1: {
        disabledDate(time) {
          let timeStamp = moment(time).valueOf();
          let nowTime = moment()
            .startOf("day")
            .valueOf();
          return timeStamp < nowTime;
        },
      },
    };
  },
  props: ["setTimeDialog", "taskId"],
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {
    setTimeDialog: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getTaskFollowDateListReq();
        }
      },
    },
  },
  methods: {
    //点击确定新增
    handleSubmit() {
      this.$refs.setTimeForm.validate((valid) => {
        if (valid) {
          this.createFollowTimeReq();
        }
      });
    },
    //查询跟进记录
    async getTaskFollowDateListReq() {
      try {
        this.loading = true;
        let res = await getTaskFollowDateList({
          id: this.taskId,
        });
        if (res.code == "OK") {
          this.followDateList = res.data;
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //添加跟进时间请求
    async createFollowTimeReq() {
      try {
        this.loading = true;
        let res = await createFollowTime({
          followDateList: [this.setTimeForm.followDate],
          taskId: this.taskId,
        });
        if (res.code == "OK") {
          this.$message.success("添加成功");
          this.$emit("getTaskBasicInfo");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleClose() {
      this.setTimeForm = {
        followDate: "",
      };
      this.$refs.setTimeForm.resetFields();
      this.$emit("handleClose", "setTimeDialog");
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
/deep/.time-dialog {
  .el-dialog__body {
    padding-top: 20px;
    .content {
      display: flex;
      // justify-content: space-between;
      .list {
        margin-left: 20px;
        max-height: 450px;
        overflow-y: auto;
      }
    }
  }
}
</style>
