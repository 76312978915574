<!--
 * @Description: 操作日志弹框
 * @Author: ChenXueLin
 * @Date: 2021-11-20 15:22:04
 * @LastEditTime: 2022-07-01 09:21:49
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <!-- 异常完成弹框 -->
    <el-dialog
      v-dialogDrag
      title="操作日志"
      :visible="visible"
      width="800px"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      :before-close="handleClose"
      v-loading="dialogLoading"
      @open="openDialog"
      custom-class="createDispatchDialog"
    >
      <div class="content">
        <div>
          <ul
            class="dynamicFiles"
            v-for="(item, index) in dataList"
            :key="index"
          >
            <div class="status" :class="index == 0 ? 'currentNode' : ''">
              {{ item.operationType }}
            </div>
            <li>
              <div class="header">
                <div>
                  <span class="userName">{{ item.userName }}</span>
                  <span class="reason" v-if="item.msg">{{ item.msg }}</span>
                </div>

                <div class="time">{{ item.updatedTime }}</div>
              </div>
            </li>
            <block v-if="item.info">
              <li
                v-if="item.info.expressNo"
                @click="toLogisticsInformation(item)"
              >
                快递单号：<span style="color:#3f90fd">{{
                  item.info.expressNo
                }}</span>
              </li>
              <li v-if="item.info.warehouseOutNo">
                出库单号：{{ item.info.warehouseOutNo }}
              </li>
              <li
                v-if="
                  item.info.voucherFileList && item.info.voucherFileList.length
                "
              >
                发货凭证：
                <el-image
                  v-for="img in item.info.voucherFileList"
                  :key="img.fileId"
                  style="width: 50px; height:50px"
                  :src="img.url"
                  :preview-src-list="
                    item.info.voucherFileList.map(ele => {
                      return ele.url;
                    })
                  "
                >
                </el-image>
              </li>
              <li v-if="item.info.inApplyNo">
                入库单号：{{ item.info.inApplyNo }}
              </li>
              <li v-if="item.info.receiveRemark">
                异常签收说明：{{ item.info.receiveRemark }}
              </li>
              <li
                v-if="item.info.signFileList && item.info.signFileList.length"
              >
                异常签收凭证：
                <el-image
                  v-for="img in item.info.signFileList"
                  :key="img.fileId"
                  style="width: 50px; height:50px"
                  :src="img.url"
                  :preview-src-list="
                    item.info.signFileList.map(ele => {
                      return ele.url;
                    })
                  "
                >
                </el-image>
              </li>
              <li v-if="item.info.details && item.info.details.length">
                <el-table :data="item.info.details">
                  <el-table-column
                    prop="materialName"
                    label="物料名称"
                    width="180"
                  >
                  </el-table-column>
                  <el-table-column prop="amount" label="发货数量" width="180">
                  </el-table-column>
                  <el-table-column prop="equipNo" label="设备号">
                  </el-table-column>
                  <el-table-column prop="receiveAmount" label="收货数量">
                  </el-table-column>
                </el-table>
              </li>
            </block>
          </ul>
        </div>
      </div>
    </el-dialog>
    <!-- 物流信息 -->
    <logistics-information
      :logisticsVisible.sync="logisticsVisible"
      :outWarehouseId="expressId"
    ></logistics-information>
  </div>
</template>

<script>
import { printError } from "@/utils/util";
import { getAllocationLog } from "@/api";
import LogisticsInformation from "@/components/logisticsInformation/logisticsInformation.vue";
export default {
  name: "logDialog",
  components: { LogisticsInformation },
  data() {
    return {
      dialogLoading: false,
      loadingBackground: "rgba(255,255,255,0.5)", // loading 遮罩颜色
      dataList: [],
      logisticsVisible: false,
      expressId: ""
    };
  },
  props: {
    logVisible: {
      type: Boolean,
      default: false
    },
    applyId: {
      type: [Number, String],
      default: ""
    }
  },
  mixins: [],
  computed: {
    visible: {
      get() {
        return this.logVisible;
      },
      set(val) {
        this.$emit("update:logVisible", val);
      }
    }
  },
  watch: {},
  methods: {
    openDialog() {
      this.getLogList();
    },
    async getLogList() {
      try {
        this.dialogLoading = true;
        let res = await getAllocationLog({
          applyId: this.applyId
        });
        if (res.code == "OK") {
          console.log(11);
          this.dataList = res.data;
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    handleClose() {
      this.$emit("update:logVisible", false);
    },
    toLogisticsInformation(row) {
      this.logisticsVisible = true;
      this.expressId = row.info.warehouseOutId;
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/.createDispatchDialog {
  .content {
    padding: 20px;
    box-sizing: border-box;
  }
  /* 时间轴 */
  .dynamicFiles {
    position: relative;
    line-height: 35px;
    font-size: 14px;
    color: #17233d;
    padding-left: 32px;
    margin-left: 100px;
    .status {
      position: absolute;
      left: -80px;
      font-weight: bold;
      top: 0px;
      width: 80px;
      text-align: right;
      color: #3f90fd;
    }
    .currentNode {
      color: goldenrod;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .userName {
      font-weight: bold;
    }
    .reason {
      margin-left: 10px;
    }
    .detail {
      margin-top: 8px;
      padding: 12px 20px;
      background: #eaf6ff;
      border-radius: 12px;
    }

    &::before {
      position: absolute;
      margin-top: 10px;
      left: 4px;
      content: "";
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: #3f90fd;
      z-index: 1;
    }
    &::after {
      position: absolute;
      top: 10px;
      left: 8px;
      bottom: -20px;
      content: "";
      width: 1px;
      border-right: 1px dashed #3f90fd;
    }
    &::not(:first-child) {
      padding-top: 20px;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
    // &:first-child {
    //   &::before {
    //     background: goldenrod;
    //   }
    // }
  }
}
</style>
