<!--
 * @Description: 新增/编辑调拨申请弹框
 * @Author: ChenXueLin
 * @Date: 2021-11-02 20:33:56
 * @LastEditTime: 2023-03-21 11:56:43
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <el-dialog
      title="调拨申请"
      :visible.sync="addVisible"
      :close-on-click-modal="false"
      width="1200px"
      v-loading="editDialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="custom-dialog"
      :before-close="closeDialog"
      append-to-body
    >
      <el-form
        label-width="90px"
        ref="addForm"
        :model="addForm"
        :rules="addFormRules"
        :inline="true"
      >
        <el-row :gutter="24">
          <!-- 工程调拨申请 -->
          <div>
            <el-col :span="24">
              <el-form-item label="调拨类型" prop="applyType">
                <el-radio-group
                  v-model="addForm.applyType"
                  @change="changeType"
                  :disabled="addType == 3"
                >
                  <div style="margin-top:8px;">
                    <el-radio
                      :label="item.codeId"
                      v-for="(item, index) in allocationApplyTypeList"
                      :key="index"
                      v-show="index < 4"
                      >{{ item.codeName }}</el-radio
                    >
                  </div>
                  <div style="margin-top:20px;">
                    <el-radio
                      :label="item.codeId"
                      v-for="(item, index) in allocationApplyTypeList"
                      :key="index"
                      v-show="index > 3"
                      >{{ item.codeName }}</el-radio
                    >
                  </div>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="调出方主体" prop="outCompanyNo">
                <e6-vr-select
                  v-model="addForm.outCompanyNo"
                  :data="companyList"
                  popperClass="popperClass"
                  :popper-append-to-body="false"
                  placeholder=""
                  title="调出方主体"
                  clearable
                  :props="{
                    id: 'organizationId',
                    label: 'organizationName',
                  }"
                  @change="
                    (val, node) => {
                      handleSelectChange(
                        val,
                        node,
                        'outCompanyName',
                        'organizationName'
                      );
                    }
                  "
                  :disabled="addType == 3"
                ></e6-vr-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="调入方主体" prop="inCompanyNo">
                <e6-vr-select
                  v-model="addForm.inCompanyNo"
                  :data="companyList"
                  popperClass="popperClass"
                  :popper-append-to-body="false"
                  placeholder=""
                  title="调入方主体"
                  clearable
                  :props="{
                    id: 'organizationId',
                    label: 'organizationName',
                  }"
                  @change="
                    (val, node) => {
                      handleSelectChange(
                        val,
                        node,
                        'inCompanyName',
                        'organizationName'
                      );
                    }
                  "
                  :disabled="addType == 3"
                ></e6-vr-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="调出工程师"
                prop="outEngineerId"
                :rules="
                  [4023002, 4023003, 4023004, 4023006, 4023008].indexOf(
                    addForm.applyType
                  ) > -1
                    ? {}
                    : addFormRules.outEngineerId
                "
              >
                <e6-vr-select
                  :disabled="
                    [4023002, 4023003, 4023004, 4023006, 4023008].indexOf(
                      addForm.applyType
                    ) > -1 || addType == 3
                  "
                  v-model="addForm.outEngineerId"
                  :data="outEngineerList"
                  placeholder="调出工程师"
                  title="调出工程师"
                  clearable
                  :props="{
                    id: 'employeeId',
                    label: 'employeeName',
                  }"
                  @change="
                    (val, node) => {
                      handleSelectChange(
                        val,
                        node,
                        'outEngineerName',
                        'employeeName'
                      );
                    }
                  "
                ></e6-vr-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="调入工程师"
                prop="inEngineerId"
                :rules="
                  [4023001, 4023003, 4023004, 4023007, 4023008].indexOf(
                    addForm.applyType
                  ) > -1
                    ? {}
                    : addFormRules.inEngineerId
                "
              >
                <e6-vr-select
                  :disabled="
                    [4023001, 4023003, 4023004, 4023007, 4023008].indexOf(
                      addForm.applyType
                    ) > -1 || addType == 3
                  "
                  v-model="addForm.inEngineerId"
                  :data="inEngineerList"
                  placeholder="调入工程师"
                  title="调入工程师"
                  clearable
                  :props="{
                    id: 'employeeId',
                    label: 'employeeName',
                  }"
                  @change="
                    (val, node) => {
                      handleSelectChange(
                        val,
                        node,
                        'inEngineerName',
                        'employeeName'
                      );
                    }
                  "
                ></e6-vr-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="出库仓" prop="outWarehouseNo">
                <e6-vr-select
                  popperClass="popperClass"
                  :popper-append-to-body="false"
                  v-model="addForm.outWarehouseNo"
                  :data="outWarehouseList"
                  placeholder=""
                  title="出库仓"
                  clearable
                  :props="{
                    id: 'warehouseNo',
                    label: 'warehouseName',
                  }"
                  @change="
                    (val, node) => {
                      handleSelectChange(
                        val,
                        node,
                        'outWarehouseName',
                        'warehouseName'
                      );
                    }
                  "
                  :disabled="addType == 3"
                ></e6-vr-select>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="仓库变更后会清空当前调拨物料，请谨慎操作"
                  placement="top-start"
                >
                  <div
                    class="el-icon-warning-outline"
                    style="font-size: 22px;color:#46BFEA"
                  ></div>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="入库仓" prop="inWarehouseNo">
                <e6-vr-select
                  popperClass="popperClass"
                  :popper-append-to-body="false"
                  v-model="addForm.inWarehouseNo"
                  :data="inWarehouseList"
                  placeholder=""
                  title="入库仓"
                  clearable
                  :props="{
                    id: 'warehouseNo',
                    label: 'warehouseName',
                  }"
                  @change="
                    (val, node) => {
                      handleSelectChange(
                        val,
                        node,
                        'inWarehouseName',
                        'warehouseName'
                      );
                    }
                  "
                  :disabled="addType == 3"
                ></e6-vr-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="调拨原因" prop="reason">
                <e6-vr-select
                  v-model="addForm.reason"
                  :data="allocationReasonList"
                  placeholder=""
                  title="调拨原因"
                  clearable
                  :props="{
                    id: 'codeId',
                    label: 'codeName',
                  }"
                  :disabled="addType == 3"
                ></e6-vr-select>
              </el-form-item>
            </el-col>
          </div>
          <el-col :span="12">
            <el-form-item label="" prop="deliveryType">
              <el-checkbox
                v-model="addForm.deliveryType"
                :true-label="1"
                :false-label="2"
                :disabled="addType == 3"
              >
                自提(无实物物流时,请勾选自提)</el-checkbox
              >
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="addForm.deliveryType == 2">
            <el-form-item label="联系人" prop="contactName">
              <el-input
                placeholder="联系人"
                v-model="addForm.contactName"
                :disabled="addType == 3"
                maxlength="32"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="addForm.deliveryType == 2">
            <el-form-item label="电话" prop="contactPhone">
              <el-input
                placeholder="电话"
                v-model="addForm.contactPhone"
                :disabled="addType == 3"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="addForm.deliveryType == 2">
            <el-form-item label="省市区地址" prop="defaultCity">
              <e6-vr-select
                :disabled="addType == 3"
                v-model="addForm.defaultCity"
                :data="addressList"
                placeholder="省市区地址"
                title="省市区地址"
                virtual
                clearable
                :is-title="true"
                remote
                @filterChange="handleLoadAddressFilter"
                @change="handleCitySelect"
                :props="{
                  id: 'fullName',
                  label: 'fullName',
                }"
                popperClass="popperClass"
                :popper-append-to-body="false"
              ></e6-vr-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="addForm.deliveryType == 2">
            <el-form-item label="地址" prop="address">
              <el-input
                placeholder="地址"
                v-model="addForm.address"
                :disabled="addType == 3"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input
                placeholder="备注"
                v-model="addForm.remark"
                type="textarea"
                :rows="2"
                :disabled="addType == 3"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 任务项设置   start -->
      <template>
        <div class="edit-content" style="margin-bottom: 20px">
          <div class="associated-info">
            <div class="right-button">
              <el-button
                type="primary"
                @click="addCloumn"
                :disabled="addType == 3"
              >
                增加调拨物料
              </el-button>
              <el-button
                type="primary"
                @click="deleteColumn"
                :disabled="addType == 3"
              >
                删除
              </el-button>
            </div>
          </div>
          <el-form ref="tableForm" :model="tableForm" :rules="tableFormRules">
            <el-table
              ref="table"
              highlight-current-row
              @selection-change="handleSelectionChange"
              :data="tableForm.tableData"
            >
              <el-table-column type="selection" width="50"></el-table-column>
              <el-table-column align="center" width="280">
                <template slot="header">
                  <i style="color: #f56c6c">*</i>
                  <span style="color: #48494c">调出仓库</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'tableData.' + scope.$index + '.outWarehouseNo'"
                    :rules="[
                      {
                        required: true,
                        message: '请选择调出仓库',
                        trigger: ['blur', 'change'],
                      },
                    ]"
                  >
                    <el-select
                      v-model="scope.row.outWarehouseNo"
                      clearable
                      filterable
                      :disabled="addType == 3"
                      style="width:230px;"
                      class="remoteSelectItem"
                      :title="getClassOptionTitle(scope.row.outWarehouseNo)"
                      @change="
                        (val) => {
                          changeOutWarehouseNo(val, scope.row);
                        }
                      "
                    >
                      <el-option
                        v-for="item in outWarehouseList"
                        :key="item.warehouseNo"
                        :label="item.warehouseName"
                        :value="item.warehouseNo"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column align="center" width="280">
                <template slot="header">
                  <i style="color: #f56c6c">*</i>
                  <span style="color: #48494c">物料名称</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'tableData.' + scope.$index + '.materialNo'"
                    :rules="tableFormRules.materialNo"
                    v-if="addType != 3"
                  >
                    <e6-vr-select
                      v-model="scope.row.materialNo"
                      :data="materialList"
                      placeholder="物料名称"
                      title="物料名称"
                      clearable
                      :props="{
                        id: 'materialNo',
                        label: 'materialName',
                      }"
                      @change="
                        (val, node) => {
                          changeRow(val, node, scope.row);
                        }
                      "
                      v-if="
                        [4023004, 4023006, 4023008].indexOf(addForm.applyType) >
                          -1
                      "
                      :disabled="addType == 3"
                    ></e6-vr-select>
                    <e6-vr-select
                      v-model="scope.row.materialNo"
                      :data="scope.row.materialList"
                      placeholder="物料名称"
                      title="物料名称"
                      clearable
                      :props="{
                        id: 'materialNo',
                        label: 'materialName',
                      }"
                      @change="
                        (val, node) => {
                          changeRow(val, node, scope.row);
                        }
                      "
                      v-else-if="
                        [4023001, 4023005, 4023007].indexOf(addForm.applyType) >
                          -1
                      "
                      :disabled="addType == 3"
                    ></e6-vr-select>
                    <remote-search-item
                      v-else
                      :value.sync="scope.row.materialNo"
                      :itemOptions="itemOptions"
                      :defaultValue="addType == 1 ? {} : scope.row"
                      :width="240"
                      @change="
                        (val, node) => {
                          changeRow(val, node, scope.row);
                        }
                      "
                      :disabled="addType == 3"
                    ></remote-search-item>
                  </el-form-item>
                  <el-form-item
                    :prop="'tableData.' + scope.$index + '.materialNo'"
                    :rules="tableFormRules.materialNo"
                    v-else
                  >
                    {{ scope.row.materialName }}
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column align="center" min-width="100">
                <template slot="header">
                  <i style="color: #f56c6c">*</i>
                  <span style="color: #48494c">物料编码</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'tableData.' + scope.$index + '.materialNo'"
                  >
                    {{ scope.row.materialNo }}
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column align="center" width="150">
                <template slot="header">
                  <i style="color: #f56c6c">*</i>
                  <span style="color: #48494c">物料数量</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'tableData.' + scope.$index + '.materialNum'"
                    :rules="tableFormRules.materialNum"
                    v-if="
                      ([4023001, 4023005, 4023007].indexOf(addForm.applyType) >
                        -1 &&
                        scope.row.isBatchManage == 0) ||
                        [4023002, 4023003, 4023004, 4023006, 4023008].indexOf(
                          addForm.applyType
                        ) > -1
                    "
                  >
                    <el-input
                      v-model="scope.row.materialNum"
                      placeholder="请输入数量"
                      :disabled="addType == 3"
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                width="150"
                v-if="[4023001, 4023005, 4023007].includes(addForm.applyType)"
              >
                <template slot="header">
                  <i style="color: #f56c6c">*</i>
                  <span style="color: #48494c">设备号</span>
                </template>
                <template slot-scope="scope">
                  <span
                    @click="handleScan(scope.row)"
                    style="color:#46BFEA;cursor: pointer;"
                    v-if="scope.row.isBatchManage == 1"
                  >
                    {{
                      scope.row.equipNoList.length
                        ? scope.row.equipNoList.join(",")
                        : "扫描或输入"
                    }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </el-form>
        </div>
      </template>
      <div class="dialog-footer" slot="footer" v-if="addType != 3">
        <el-button class="cancel" @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </el-dialog>
    <!-- 扫描输入框 -->
    <scan-input
      :inputVisible.sync="inputVisible"
      :maxLength="clickRow.leftAmount"
      :defaultValue="clickRow.equipNoList"
      @confirm="confirmInput"
    ></scan-input>
  </div>
</template>

<script>
import base from "@/mixins/base";
import ScanInput from "./scanInput/scanInput.vue";
import {
  saveEngineeringAllocationApply,
  findDownList,
  queryMaterialList,
  getEngineeringApplyDetail,
  queryStockOrganization,
  queryStockWarehouse,
  getAllocationApplyDetail,
  saveAllocationApply,
  getEngineer,
  queryEnginerStockList,
  getAddressData,
} from "@/api";

import { mobileValid } from "@/utils/validate";
import { printError } from "@/utils/util";
import { positiveIntegerValid } from "@/utils/validate";
export default {
  name: "addTransferDialog",
  components: { ScanInput },
  data() {
    return {
      itemOptions: {
        remote: true,
        labelKey: "materialName",
        valueKey: "materialNo",
      },
      visible: false,
      editDialogLoading: false,
      queryMaterialList: queryMaterialList,
      allocationApplyTypeList: [], //调拨类型
      allocationReasonList: [], //调拨原因
      outWarehouseList: [], //调出仓列表
      inWarehouseList: [], //入库仓列表
      companyList: [], //主体列表
      addForm: {
        applyType: "", //调拨类型
        inCompanyNo: "", //调入方主体
        inCompanyName: "", //调入方主体名称
        outCompanyNo: "", //调出方主体
        outCompanyName: "", //调出方主体名称
        reason: "", //调拨原因
        outWarehouseNo: "", //出库仓
        outWarehouseName: "", //出仓名称
        outWarehouseId: "", //出库仓库ID
        inWarehouseNo: "", //入库仓
        inWarehouseName: "", //入仓名称
        contactName: "", //联系人
        contactPhone: "", //电话
        defaultCity: "", //默认选中地址
        provinceId: "",
        provinceName: "",
        cityName: "",
        cityId: "",
        countyName: "",
        countyId: "",
        streetName: "",
        streetId: "",
        address: "", //地址
        remark: "", //备注
        inEngineerId: "",
        inEngineerName: "",
        outEngineerId: "",
        outEngineerName: "",
        deliveryType: 2, //是否自提
      },
      addressList: [],
      materialList: [],
      addFormRules: {},
      tableForm: {
        tableData: [],
      },
      tableFormRules: {
        materialNo: [
          {
            required: true,
            message: "请选择物料名称",
            trigger: ["blur", "change"],
          },
        ],
        materialNum: positiveIntegerValid.required({
          requiredMsg: "请输入物料数量",
          message: "只能为>0的整数",
          trigger: ["blur", "change"],
        }),
      },
      outEngineerList: [], //调出工程师
      inEngineerList: [], //调入工程师
      selectedData: [], //选中的数据
      clickRow: {},
      inputVisible: false,
    };
  },
  props: {
    addVisible: {
      type: Boolean,
      default: false,
    },
    //弹框类型1新增调拨申请2新增工程师调拨申请
    dialogType: {
      type: [String, Number],
      default: "1",
    },
    //1新增2编辑3详情
    addType: {
      type: [String, Number],
      default: 1,
    },
    //编辑时的id
    id: {
      type: [String, Number],
    },
  },
  mixins: [base],
  computed: {},
  created() {
    this.loadAddressData();
  },
  watch: {
    //弹框显示
    addVisible: {
      immediate: true,
      handler(val) {
        if (val) {
          let rules = {
            contactName: [
              {
                required: true,
                message: "请输入联系人",
                trigger: ["blur", "change"],
              },
            ],
            contactPhone: mobileValid.required({
              requiredMsg: "请输入手机号码",
              message: "号码格式不正确",
              trigger: ["blur", "change"],
            }),
            defaultCity: [
              {
                required: true,
                message: "请选择地址",
                trigger: ["blur", "change"],
              },
            ],
            address: [
              {
                required: true,
                message: "请输入详细地址",
                trigger: ["blur", "change"],
              },
            ],
            // reason: [
            //   {
            //     required: true,
            //     message: "请选择调拨原因",
            //     trigger: ["blur", "change"],
            //   },
            // ],
            outCompanyNo: [
              {
                required: true,
                message: "请选择调出方主体",
                trigger: ["blur", "change"],
              },
            ],
            inWarehouseNo: [
              {
                required: true,
                message: "请选择入库仓",
                trigger: ["blur", "change"],
              },
            ],
          };
          let newRules;
          if (this.dialogType == 1) {
            //调拨申请
            newRules = {
              inCompanyNo: [
                {
                  required: true,
                  message: "请选择调入方主体",
                  trigger: ["blur", "change"],
                },
              ],
            };
          } else {
            newRules = {
              applyType: [
                {
                  required: true,
                  message: "请选择调拨类型",
                  trigger: ["blur", "change"],
                },
              ],
              inCompanyNo: [
                {
                  required: true,
                  message: "请选择调入方主体",
                  trigger: ["blur", "change"],
                },
              ],
              // outWarehouseNo: [
              //   {
              //     required: true,
              //     message: "请选择出库仓",
              //     trigger: ["blur", "change"],
              //   },
              // ],
              inEngineerId: [
                {
                  required: true,
                  message: "请选择调入工程师",
                  trigger: ["blur", "change"],
                },
              ],
              outEngineerId: [
                {
                  required: true,
                  message: "请选择调出工程师",
                  trigger: ["blur", "change"],
                },
              ],
            };
          }
          this.addFormRules = { ...rules, ...newRules };
          this.$nextTick(() => {
            this.$refs.addForm.clearValidate();
          });
          this.initData();
          //编辑或详情需要先获取
          if (this.addType == 2 || this.addType == 3) {
            if (this.dialogType == 1) {
              this.getAllocationApplyDetailReq();
            } else {
              this.getEngineeringApplyDetailReq();
            }
          }
        }
      },
    },
    //调入方主体
    "addForm.inCompanyNo": {
      immediate: true,
      handler(val) {
        if (val) {
          this.queryWarehouseReq(
            val,
            "in",
            this.addForm.applyType == 4023002 ||
              this.addForm.applyType == 4023005
              ? this.addForm.inEngineerId
              : ""
          );
        }
      },
    },
    //调出方主体
    "addForm.outCompanyNo": {
      immediate: true,
      handler(val) {
        if (val) {
          this.queryWarehouseReq(
            val,
            "out",
            this.addForm.applyType == 4023001 ||
              this.addForm.applyType == 4023005
              ? this.addForm.outEngineerId
              : ""
          );
        }
      },
    },
    //调入工程师
    "addForm.inEngineerId": {
      immediate: true,
      handler(val) {
        if (val) {
          if (this.addForm.inCompanyNo) {
            this.queryWarehouseReq(this.addForm.inCompanyNo, "in", val);
          }
        }
      },
    },
    //调出工程师
    "addForm.outEngineerId": {
      immediate: true,
      handler(val) {
        if (val) {
          if (this.addForm.outCompanyNo) {
            this.queryWarehouseReq(this.addForm.outCompanyNo, "out", val);
          }
        }
      },
    },
  },
  methods: {
    //点击扫描设备号
    handleScan(row) {
      this.inputVisible = true;
      this.clickRow = row;
    },
    //输入完成
    confirmInput(data) {
      this.$nextTick(() => {
        this.clickRow.equipNoList = data;
        this.clickRow.materialNum = data.length;
      });
    },
    async initData() {
      let promiseList = [
        findDownList(["allocationApplyType", "allocationReason"]),
        queryStockOrganization({}),
        getEngineer({ isAllEngineer: 1 }),
        getEngineer({}),
      ];
      let [
        enumRes,
        companyRes,
        outEngineerRes,
        inEngineerRes,
      ] = await Promise.all(promiseList);
      //调拨类型下拉框
      this.allocationApplyTypeList = this.getFreezeResponse(enumRes, {
        path: "data.allocationApplyType",
      });
      //调拨原因下拉框
      this.allocationReasonList = this.getFreezeResponse(enumRes, {
        path: "data.allocationReason",
      });
      //主体
      let companyList = this.getFreezeResponse(companyRes, {
        path: "data",
      });
      this.companyList = companyList.filter((item) => {
        return item;
      });
      //调出工程师下拉框
      this.outEngineerList = this.getFreezeResponse(outEngineerRes, {
        path: "data",
      });
      //调入工程师下拉框
      this.inEngineerList = this.getFreezeResponse(inEngineerRes, {
        path: "data",
      });
    },
    //过滤title
    getClassOptionTitle(val) {
      if (val !== "") {
        let result = this.outWarehouseList.filter(
          (item) => item.warehouseNo === val
        );
        if (result.length > 0) {
          return result[0].warehouseName;
        }
      }
    },
    changeOutWarehouseNo(val, row) {
      if (val) {
        row.outWarehouseName = this.outWarehouseList.find((item) => {
          return item.warehouseNo == val;
        }).warehouseName;
        if ([4023001, 4023005, 4023007].indexOf(this.addForm.applyType) > -1) {
          console.log(this.queryEnginerMaterialListReq(row));
          this.queryEnginerMaterialListReq(row);
        }
      } else {
        row.outWarehouseName = "";
      }
    },
    //获取每行的物料
    async queryEnginerMaterialListReq(row) {
      try {
        //4023002：总仓-工程师
        //4023003：总仓-工程师仓
        //4023004：工程师仓-总仓
        //4023001：工程师-总仓
        //4023005：工程师-工程师
        //4023006：工程师仓-工程师
        //4023007：工程师-工程师仓
        let applyType = this.addForm.applyType;
        let havingId, havingType;
        //调出为工程师的
        if ([4023001, 4023005, 4023007].indexOf(applyType) > -1) {
          havingId = this.addForm.outEngineerId;
          havingType = 2;
        }
        let res = await queryEnginerStockList({
          havingId, //仓库ID/工程师ID
          havingType, //1仓库，2工程师
          warehouseNo: row.outWarehouseNo, //工程师仓编号
          organizationNo: this.addForm.outCompanyNo, //组织编号
        });
        row.materialList = res.data;
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    //获取调拨详情
    async getAllocationApplyDetailReq() {
      try {
        this.editDialogLoading = true;
        let res = await getAllocationApplyDetail({
          id: this.id,
        });
        this.handleDetailData(_.cloneDeep(res.data));
        this.getStockListData();
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    //获取工程师调拨详情
    async getEngineeringApplyDetailReq() {
      try {
        this.editDialogLoading = true;
        let res = await getEngineeringApplyDetail({
          id: this.id,
        });
        this.handleDetailData(_.cloneDeep(res.data));
        this.getStockListData();
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    //获取详情后处理数据回显
    handleDetailData(addForm) {
      let newAddForm = { ...addForm };
      newAddForm.applyType = addForm.applyType * 1;
      newAddForm.applyStatus = addForm.applyStatus * 1;
      newAddForm.reason = addForm.reason * 1;
      newAddForm.outEngineerId = newAddForm.outEngineerId
        ? newAddForm.outEngineerId
        : "";
      newAddForm.inEngineerId = newAddForm.inEngineerId
        ? newAddForm.inEngineerId
        : "";
      let provinceName = addForm.provinceName ? addForm.provinceName : "";
      let cityName = addForm.cityName ? addForm.cityName : "";
      let countyName = addForm.countyName ? addForm.countyName : "";
      let streetName = addForm.streetName ? addForm.streetName : "";
      newAddForm.defaultCity =
        provinceName + cityName + countyName + streetName;
      this.loadAddressData(newAddForm.defaultCity);
      this.addForm = newAddForm;
      let saveReqVOList = newAddForm.saveReqVOList;
      saveReqVOList.map((item) => {
        item.materialList = [
          { materialNo: item.materialNo, materialName: item.materialName },
        ];
      });
      this.tableForm.tableData = saveReqVOList;
    },
    //获取工程师或仓库下的物料
    async queryEnginerStockListReq() {
      try {
        //4023002：总仓-工程师
        //4023003：总仓-工程师仓
        //4023004：工程师仓-总仓
        //4023001：工程师-总仓
        //4023005：工程师-工程师
        //4023006：工程师仓-工程师
        //4023007：工程师-工程师仓
        let applyType = this.addForm.applyType;
        let havingId, havingType;
        //调出为工程师的
        if ([4023001, 4023005, 4023007].indexOf(applyType) > -1) {
          havingId = this.addForm.outEngineerId;
          havingType = 2;
        }
        if ([4023004, 4023006, 4023008].indexOf(applyType) > -1) {
          havingId = this.addForm.outWarehouseId;
          havingType = 1;
        }
        let res = await queryEnginerStockList({
          havingId, //仓库ID/工程师ID
          havingType, //1仓库，2工程师
          warehouseNo: this.addForm.outWarehouseNo, //工程师仓编号
          organizationNo: this.addForm.outCompanyNo, //组织编号
        });
        this.materialList = res.data;
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    //修改调拨类型
    changeType() {
      //4023002：总仓-工程师
      //4023003：总仓-工程师仓
      //4023004：工程师仓-总仓
      //4023001：工程师-总仓
      //4023005：工程师-工程师
      //4023006：工程师仓-工程师
      //4023007：工程师-工程师仓
      if (this.addForm.inCompanyNo) {
        this.queryWarehouseReq(
          this.addForm.inCompanyNo,
          "in",
          [4023002, 4023005, 4023006].indexOf(this.addForm.applyType) > -1
            ? this.addForm.inEngineerId
            : ""
        );
      }
      if (this.addForm.outCompanyNo) {
        this.queryWarehouseReq(
          this.addForm.outCompanyNo,
          "out",
          [4023001, 4023005, 4023007].indexOf(this.addForm.applyType) > -1
            ? this.addForm.outEngineerId
            : ""
        );
      }
      if (
        [4023002, 4023003, 4023004, 4023006, 4023008].indexOf(
          this.addForm.applyType > -1
        )
      ) {
        this.addForm.outEngineerId = "";
        this.addForm.outEngineerName = "";
      }
      if (
        [4023001, 4023003, 4023004, 4023007, 4023008].indexOf(
          this.addForm.applyType > -1
        )
      ) {
        this.addForm.inEngineerId = "";
        this.addForm.inEngineerName = "";
      }
      this.inWarehouseList = [];
      this.outWarehouseList = [];
      this.addForm.outWarehouseName = "";
      this.addForm.outWarehouseNo = "";
      this.addForm.inWarehouseName = "";
      this.addForm.inWarehouseNo = "";
      this.materialList = [];
      this.tableForm.tableData = [];
    },
    //下拉选择
    handleSelectChange(val, node, prop, key) {
      this.addForm[prop] = node[key];
      if (prop == "outWarehouseName") {
        this.addForm.outWarehouseId = node.warehouseId;
        this.addForm.outWarehouseName = node.warehouseName;
      }
      //调入方主体修改的时候，调入仓库需要置空
      if (prop == "inCompanyName") {
        this.addForm.inWarehouseNo = "";
        this.addForm.inWarehouseName = "";
      }

      if (prop == "inEngineerName") {
        this.addForm.inWarehouseNo = "";
        this.addForm.inWarehouseName = "";
      }
      if (prop == "outEngineerName") {
        this.addForm.outWarehouseNo = "";
        this.addForm.outWarehouseName = "";
      }
      //选中调入仓库需回显联系信息和地址
      if (prop == "inWarehouseName") {
        this.addForm.contactName = node.description.trim()
          ? node.description.trim()
          : ""; //联系人
        this.addForm.contactPhone = node.tel.trim() ? node.tel.trim() : ""; //电话
        let provinceName = node.provinceName ? node.provinceName : "";
        let cityName = node.cityName ? node.cityName : "";
        let countyName = node.countryName ? node.countryName : "";
        let streetName = node.streetName ? node.streetName : "";
        this.addForm.provinceId = node.provinceId ? node.provinceId : "";
        this.addForm.provinceName = provinceName;
        this.addForm.cityName = cityName;
        this.addForm.cityId = node.cityId ? node.cityId : "";
        this.addForm.countyName = countyName;
        this.addForm.countyId = node.countryId ? node.countryId : "";
        this.addForm.streetName = streetName;
        this.addForm.streetId = node.streetId ? node.streetId : "";
        this.addForm.address = node.address ? node.address : ""; //地址
        this.addForm.defaultCity =
          provinceName + cityName + countyName + streetName;
        this.loadAddressData(this.addForm.defaultCity);
      }
      if (
        [4023001, 4023004, 4023005, 4023006, 4023007, 4023008].indexOf(
          this.addForm.applyType
        ) > -1 &&
        ["outWarehouseName", "outEngineerName", "outCompanyName"].indexOf(
          prop
        ) > -1
      ) {
        this.materialList = [];
        this.tableForm.tableData = [];
      }
    },
    // 处理城市选择
    handleCitySelect(select, node) {
      let val = _.cloneDeep(node);
      this.addForm.provinceId = val.provinceCode;
      this.addForm.provinceName = val.provinceName;
      this.addForm.cityId = val.cityCode;
      this.addForm.cityName = val.cityName;
      this.addForm.countyId = val.countyCode;
      this.addForm.countyName = val.countyName;
      this.addForm.streetId = val.streetCode;
      this.addForm.streetName = val.streetName;
      this.addForm.defaultCity = val.fullName;
    },
    resetAddress() {
      this.addForm.provinceId = "";
      this.addForm.provinceName = "";
      this.addForm.cityId = "";
      this.addForm.cityName = "";
      this.addForm.countyId = "";
      this.addForm.countyName = "";
      this.addForm.streetId = "";
      this.addForm.streetName = "";
      this.addForm.defaultCity = "";
    },
    handleLoadAddressFilter: _.debounce(async function(val) {
      if (val) {
        this.resetAddress();
        this.loadAddressData(val);
      }
    }, 300),
    //加载地址列表
    async loadAddressData(val = "") {
      try {
        let res = await getAddressData({
          addressName: val,
        });
        //获取地址下拉框
        this.addressList = this.getFreezeResponse(res, {
          path: "data",
        });
      } catch (error) {
        printError(error);
      }
    },
    //选择物料名称
    changeRow(value, node, row) {
      console.log(value, node, row);
      row.isBatchManage = node.isBatchManage;
      if (value) {
        row.materialName = node.materialName;
        row.materialType = node.materialType;
      } else {
        row.materialNo = "";
      }
    },
    //多选勾中的数据
    handleSelectionChange(columns) {
      this.selectedData = columns;
    },
    //选择列表中的调出库下拉框
    handleSelectRowChange(value, node, row) {
      row.outWarehouseName = node.warehouseName;
    },
    //点击添加物料
    addCloumn() {
      let tableData = this.tableForm.tableData;
      let warehouseNo = this.addForm.outWarehouseNo;
      let outWarehouseName = this.addForm.outWarehouseName;
      let row = {
        materialName: "",
        materialNo: "",
        materialNum: "",
        outWarehouseNo: warehouseNo,
        outWarehouseName: outWarehouseName,
        equipNoList: [],
        materialList: [],
      };
      tableData.push(row);
      this.tableForm.tableData = tableData;
      this.getStockListData(row);
    },
    getStockListData(row) {
      //4023002：总仓-工程师
      //4023003：总仓-工程师仓
      //4023004：工程师仓-总仓
      //4023001：工程师-总仓
      //4023005：工程师-工程师
      //4023006：工程师仓-工程师
      //4023007：工程师-工程师仓
      let applyType = this.addForm.applyType;
      if ([4023004, 4023006, 4023008].indexOf(applyType) > -1) {
        if (!this.addForm.outCompanyNo) {
          this.$message.warning("请选择调出方主体");
          return;
        }
        if (!this.addForm.outWarehouseNo) {
          this.$message.warning("请选择出库仓");
          return;
        }
        this.queryEnginerStockListReq();
      }
      if ([4023001, 4023005, 4023007].indexOf(applyType) > -1) {
        if (!this.addForm.outEngineerId) {
          this.$message.warning("请选择调出工程师");
          return;
        }
        this.queryEnginerMaterialListReq(row);
      }
    },
    //点击删除行
    deleteColumn() {
      let tableData = this.tableForm.tableData;
      tableData = tableData.filter((item) => {
        return !this.selectedData.some(
          (i) =>
            item.materialName == i.materialName &&
            i.materialNo == item.materialNo &&
            item.materialNum == i.materialNum
        );
      });

      this.tableForm.tableData = tableData;
    },
    deleteRow(index) {
      let tableData = _.cloneDeep(this.tableForm.tableData);
      tableData.splice(index, 1);
      this.tableForm.tableData = tableData;
    },
    //查询调出仓库、调入仓库
    async queryWarehouseReq(organizationId, type, engineerId) {
      try {
        let res = await queryStockWarehouse({
          organizationId,
          engineerId: engineerId ? engineerId : this.addForm.engineerId,
          queryType: this.dialogType == 2 ? 1 : "",
          allocationApplyType:
            this.dialogType == 2 ? this.addForm.applyType : "",
          isEnterWarehouse: type == "in" ? 1 : 0,
        });
        if (res.code == "OK") {
          if (type == "out") {
            this.outWarehouseList = res.data;
          } else {
            this.inWarehouseList = res.data;
          }
        }
      } catch (error) {
        printError(error);
      }
    },
    //点击确定添加
    handleSubmit() {
      const p1 = new Promise((resolve) => {
        this.$refs["addForm"].validate((valid) => {
          if (valid) resolve();
        });
      });
      //服务内容填写的校验
      const p2 = new Promise((resolve) => {
        this.$refs["tableForm"].validate((valid) => {
          if (valid) resolve();
        });
      });
      Promise.all([p1, p2]).then(() => {
        if (!this.tableForm.tableData.length) {
          this.$message.warning("调拨物料不能为空,请添加调拨物料后重试");
          return;
        }
        if (this.dialogType == 1) {
          this.saveAllocationApplyReq();
        } else {
          this.saveEngineeringAllocationApply();
        }
      });
    },
    //添加/编辑工程师调拨申请请求
    async saveEngineeringAllocationApply() {
      try {
        this.editDialogLoading = true;
        let res = await saveEngineeringAllocationApply({
          ...this.addForm,
          saveReqVOList: this.tableForm.tableData,
        });
        if (res.code == "OK") {
          let msg =
            this.addType == 1
              ? "添加成功"
              : this.addType == 2
              ? "编辑成功"
              : "详情";
          this.$message.success(msg);
          this.$emit("refreshData");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    //添加/编辑调拨申请请求
    async saveAllocationApplyReq() {
      try {
        this.editDialogLoading = true;
        let res = await saveAllocationApply({
          ...this.addForm,
          saveReqVOList: this.tableForm.tableData,
        });
        if (res.code == "OK") {
          let msg =
            this.addType == 1
              ? "添加成功"
              : this.addType == 2
              ? "编辑成功"
              : "详情";
          this.$message.success(msg);
          this.$emit("refreshData");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    //关闭弹框
    closeDialog() {
      this.addForm = {
        applyType: "", //调拨类型
        outWarehouseId: "",
        inCompanyNo: "", //调入方主体
        inCompanyName: "", //调入方主体名称
        outCompanyNo: "", //调出方主体
        outCompanyName: "", //调出方主体名称
        reason: "", //调拨原因
        outWarehouseNo: "", //出库仓
        outWarehouseName: "", //出仓名称
        inWarehouseNo: "", //入库仓
        inWarehouseName: "", //入仓名称
        contactName: "", //联系人
        contactPhone: "", //电话
        defaultCity: "",
        provinceId: "",
        provinceName: "",
        cityName: "",
        cityId: "",
        countyName: "",
        countyId: "",
        streetName: "",
        streetId: "",
        address: "", //地址
        inEngineerId: "",
        inEngineerName: "",
        outEngineerId: "",
        outEngineerName: "",
        deliveryType: 2,
        remark: "", //备注
      };
      this.materialList = [];
      this.tableForm.tableData = [];
      this.$refs.addForm.resetFields();
      this.$emit("update:addVisible", false);
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 20px;
    box-sizing: border-box;
    // 公司主体下拉框宽度设置
    .popperClass {
      width: 320px !important;
      text-align: left;
    }
    .el-form {
      .el-input {
        width: 220px;
      }
      .el-textarea {
        width: 500px;
      }
    }

    .el-form {
      .el-table {
        .el-form-item {
          margin-bottom: 12px;
          .e6-vr-select {
            width: 280px;
            .el-input {
              width: 270px;
            }
          }
          .el-input {
            width: 140px;
          }
        }
      }
    }
  }
  .associated-info {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
  }
}
.search-corpName {
  width: 280px;
}
</style>
